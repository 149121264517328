$font-password: "password";
$font-size-14: 0.875rem;

$font-color: #707070;
$color-positive: #59d08e;
$main-backgound: "";

html,
body {
  height: 100%;
  // background: linear-gradient(180deg, #12b6df 0%, #f8efe4 49.48%);
  background: #1a1a1a !important;
  font-family: "montserrat";
  /* Standard syntax (must be last) */
}

/*!****************   Common Component 
******************/
.loading-container{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10em;
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #1A1A1A;
  top: 0;
  left: 0;
  transition: all .5s ease;
  will-change: bottom;
  z-index: 999999;
  &.--hide{
    transition: all .5s ease;
    bottom: 100%;
    transform: translateY(-100%)
  }
  .loading{
    display: grid;
    place-items: center;
    label{
      margin-top: 1em;
      width: 100%;
      color: #EDEBEB;
    }
    .loading-dots::after {
      content: '';
      display: inline-block;
      width: 1em;
      animation: dot-animation 1s infinite;
    }
    
    @keyframes dot-animation {
      0% { content: '.'; }
      33% { content: '..'; }
      67% { content: '...'; }
      100% { content: ''; }
    }
  }
}

.p-skeleton{
  background-color: #95959517 !important;
}

.Toastify{
  .Toastify__toast-container{
    .Toastify__toast{
      background-color: #1A1A1A;
      margin: 0.5em;
      border-radius: 0.5em;
      ._label{
        font-family: 'cabin';
        font-size: 1rem;
        color: #B1B1C9;
        text-transform: capitalize;
        &.--order-status{
          color: #626262;
          font-size: 0.75rem;
        }
        &.--total-title{
          width: 100%;
          font-size: 0.75rem;
          color: #6F7283;
          ._label{
            color: #B1B1C9;
            font-size: 0.75rem;
          }
        }
        &.--amount{
          width: 100%;
          font-size: 0.75rem;
          color: #EDEBEB;
        }
      }
      .progressbar{
        background: #F0A000;
      }
    }
    @media (max-width: 991px) {
      .Toastify__toast{
        margin: 0.5em;
        border-radius: 0.5em;
      }
  }
  }
}

.text-vertical-scroll{
  text-align: center;
  .wrap {
    height: 30px;
    // width: 400px;
    overflow: auto;
    /* overflow-x: scroll; */
  }
  .msg-wrap {
    position: relative;
    height: 100%;
    width: 100%!important;
  }
  .msg-item {
    display: inline-block;
    float: left;
    line-height: 30px;
    padding: 0 10px;
    // width: 160px;
    background: transparent;
    color: #EDEBEB;
  }
  
}
.wallet-summary-container{
  padding-top: 2em;
  .header-container{
    font-family: 'Montserrat';
    label{
      color: #F0A000 !important;
      font-size: 1.625rem;
    }
    span{
      color: #9E9E9E;
      font-size: 1rem;
    }
  }
  .wallet-summary-box{
    background-color: #0D0D0F;
    border-radius: 1.5em;
    margin-top: 1em;
    padding: 2em;
    padding-bottom: 2em;
    .text-detail{
      padding-left: 1em;
    }
    .title{
      font-size: 0.875rem;
      font-family: 'cabin';
      color: #9E9E9E;
      span{
        margin-left: 0.5em;
        background-color: #9E9E9E;
        border-radius: 50%;
      }
    }
    .price-value{
      font-family: "montserrat";
      font-weight: bold;
      font-size: 1.625rem;
      color: #FFFFFF;
    }
    .usdt-text{
      font-size: 1rem;
      font-family: "montserrat";
      color: #FFFFFF;
    }
    .usd-text{
      font-size: 16px;
      font-size: 0.75rem;
      font-family: "montserrat";
    }
    .listing{
      border-top: 0.1em solid #9e9e9e26;
      border-bottom: 0.1em solid #9e9e9e26;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      margin-top: 0.5em;
    }
    @media (min-width: 992px) {
      .listing{
        border: none;
      }
    }
    .progress{
      border-radius: 2em;
      background: #2A2A2A;
      .progress-bar{
        border-radius: 0 2em 2em 0;
        &.bg-red{
          background: #FA6364;
        } 
        &.bg-yellow{
          background: #F0A000;
        } 
        &.bg-green{
          background: #59D08E;
        }
      }
    }
    .line-container{
      margin-top: 0.5em;
      border-top: 0.1em solid #9e9e9e26;
    }
  }
  .history-btn{
    width: 100%;
    font-size: 1.125rem;
    margin-top: 1em;
    background: #0D0D0F;
    border: 1px solid rgba(171, 174, 205, 0.3);
    box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
    border-radius: 16px;
    padding: 0.5em;
    color: #EDEBEB;
    font-family: "montserrat";
  }
  .withdraw-button{
    font-size: 1.125rem;
    font-family: "montserrat";
    border-radius: 0.5em;
    background: #2A2A2A;
    color: #9E9E9E;
    border: none;
    padding: 0.35em 0em;
    margin: 0 0.5em;
    width: 8em;
    word-break: break-word;
    margin-left: 0;
  }
  .deposit-button{
    color: black;
    margin: 0.35em 0;
    font-size: 1.125rem;
    font-family: "montserrat";
    border-radius: 0.5em;
    background: linear-gradient(256.56deg, #F0A000 38.01%, #FFDC00 102.8%);
    border: none;
    padding: 0.35em 0em;
    width: 8em;
    word-break: break-word;
  }
}
.wallet-history-data::-webkit-scrollbar {
  display: none !important;
}

.tab-wallet-history{
  width: auto;
  margin: 0 0.5em;
  color: #9E9E9E;
  background-color: #2A2A2A;
  border: 0.2em solid #2A2A2A;
  border-radius: 2em;
  padding: 0.1em 1em;
  font-size: 14px;
  white-space: nowrap;
  &.active{
    background: #1A1A1A;
    border: 0.2em solid #2A2A2A;
    color: #EDEBEB;
  }
}

.wallet-history-container{
  outline: none;
  background: #1A1A1A;
  height: 40em;
  // overflow: hidden;
  border-radius: 1em;
  width: 45em;
  @media (max-width: 572px) {
    height: 100%;
    border-radius: 0em;
  }
  .modal-dialog{
    .modal-content{
      height: 100vh;
      overflow: hidden;
      background: #1A1A1A;
      border-radius: 1rem!important;
      .modal-body{
        background: #1A1A1A;
        padding-bottom: 8em;
        border-radius: 1em;
      }
    }
    @media (max-width: 767px) {
      .modal-content{
        border-radius: 0rem !important;
      }
    }
  }
  @media (max-width: 575px) {
    .modal-dialog{
      margin: 0;
      height: 100%;
    }
  }
  .common-header-main{
    margin-left: 0;
    display: contents;
    position: absolute;
    align-items: end;
    padding: 1em 2em;
    .back-btn{
      padding: 0em 1.5em;
    }
  }
  .content-container{
    min-height:10em;
    background: #1A1A1A;
    border-radius: 2em 2em 0 0;
    position: relative;
    margin-top: 5em;
    font-family: 'Montserrat';
    text-transform: capitalize;
    .data-listing{
      margin-top: 1em;
      overflow: scroll;
      height: 28em;
      padding: 1em;
      @media (max-width: 572px) {
        height: 35em;

      }
    }
    .data-listing::-webkit-scrollbar {
      display: none !important;
    }
    .row{
      position: relative;
    }
    .--share-button{
      position: absolute;
      right: 0;
      top: 0em;
      width: auto;
    }
    .status-text{
      font-size: 0.75rem;
      &.red{
        color: #FA6364;
      }
      &.green{
        color: #59D08E;
      }
    }
    .bold-text{
      color: #9E9E9E;
      font-weight: 500;
      font-size: 0.75rem;
      &.--long{
        color: rgb(89, 208, 142);
      }
      &.--short{
        color: rgb(250, 99, 100);
      }
      &.--filled{
        color: #59D08E;
        font-size:  0.75rem;
        align-items: end;
        display: flex;
        justify-content: end;
      }
      &.--pending{
        color: #9091A0;
        font-size:  0.75rem;
        align-items: end;
        display: flex;
        justify-content: end;
      }
      &.--canceled{
        color: #FA6364;
        font-size:  0.75rem;
        align-items: end;
        display: flex;
        justify-content: end;
      }
    }
    .normal-text{
      font-weight: normal;
      color: #9091A0;
      font-size: 0.625rem;
    }
  }
  .modal-header-image{
    // background-image: url(./assets/images/background/announcement1.png);
    height: 20em;
    width: 100%;
    top: 0;
    right: 0;
    // transform: translate(-10%);
    position: absolute;
    background-size: contain;
  }
  .line-container{
    margin: 1.5em 0em;
    background-color: #9e9e9e2a;
    width: 100%;
    height: 0.1em;
  }
  .trade-order-container{
    
    .modal-dialog{
      height: auto;
      transform: translate(-50%, -50%) !important;
      position: absolute;
      width: auto;
      top: 50%;
      left: 50%;
      margin: 0;
      pointer-events: none;
      .modal-content{
        border-radius: 1em !important;
        height: auto;
      }
    }
  }
}

.admin-portal-container{
  padding: 5em;
  font-family: "montserrat";
  .admin-portal-row{
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
    input{
      border: none;
      padding: 0.5em 1em;
      border-radius: 0.5em 0em 0em 0.5em;
      outline: none;
    }
    button{
      background: linear-gradient(256.56deg, #F0A000 38.01%, #FFDC00 102.8%);
      padding: 0.5em;
      border: none;
      border-radius: 0 0.5em 0.5em 0;
    }
  }
  .admin-portal-detail-container{
    margin-top: 3em;
    padding: 5em;
    background: #242628;
    border-radius: 0.5em;
    color: #FFFFFF;
    label{
      word-wrap: break-word;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
  @media (max-width: 991px) {
    .admin-portal-detail-container{
      padding: 3em 2em;
    }
}
}

.layout-component {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  .background-image {
    position: absolute;
    // top: -28em;
  }
}

.nowrap {
  white-space: nowrap;
}

.common-header-main {
  z-index: 1;
  font-family: "montserrat";
  position: relative;
  height: 5em;
  z-index: 4;
  display: grid;
  align-items: center;
  animation: fadeIn 500ms ease-in-out;
  .input-search {
    font-size: 0.875rem;
    background: transparent;
    border: none;
    text-align: end;
    color: #9e9e9e;
    padding: 0 1em;
    width: 100%;
    animation: fadeIn 500ms ease-in-out;
  }
  .input-search:focus {
    outline: none;
    color: #9e9e9e;
  }
  .input-search::placeholder {
    color: #9e9e9e;
  }
  button {
    background-color: transparent;
    border: none;
    display: flex;
  }
  .connect-btn {
    border: none;
    font-size: 1rem;
    position: absolute;
    top: 2.5em;
    right: 1em;
    background-color: #ffdc00;
    color: #2a2a2a;
    border-radius: 5em;
    text-transform: capitalize;
    font-weight: bold;
    padding: 0.4em;
    text-align: center;
    display: flex;
    align-items: center;
    img {
      margin-left: 0.5em;
    }
    button {
      color: #2a2a2a;
    }
  }
  .round-btn {
    background: #484848;
    width: 2.5em;
    height: 2.5em;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    // animation: moveToleft 0.8s ease-in;
  }
  .back-btn {
    display: flex;
    justify-content: start;

    &.after {
      .round-btn {
        overflow: hidden;
        animation: moveToRight 0.5s ease-in;
        background: #484848;
        width: 100%;
        height: 2.5em;
        justify-content: end;
        padding: 1em;
        display: flex;
        align-items: center;
        border-radius: 5em;
      }
    }
  }
  .share-btn {
    display: flex;
    justify-content: end;
  }
  .connect-btn-login {
    border: none;
    font-size: 1rem;
    position: absolute;
    top: 2.5em;
    right: 1em;
    width: 10.1em;
    border-radius: 5em;
    text-transform: capitalize;
    font-weight: bold;
    padding: 0.5rem 1em;
    text-align: center;
    display: flex;
    align-items: center;
    background: #2a2a2a;
    label {
      color: #ffdc00;
      text-overflow: ellipsis;
      overflow: hidden;
      // width: 1px;
      white-space: nowrap;
    }
  }
  .setting-container {
    background: #2a2a2a;
    position: absolute;
    bottom: -3em;
    width: 10em;
    border-radius: 1em;
    height: 2.5em;
    right: 0%;
    transform: translate(-10%);
    align-items: start;
    display: grid;
    padding: 0.5em;
    z-index: 5;
    &.recover-key {
      top: 5.5em;
      right: -0.5em;
      height: 12em;
      width: 15em;
      padding: 1em;
      align-items: center;
      z-index: 9999;
      label {
        color: #cdc7c7;
        text-align: start;
        font-size: medium;
        span {
          font-size: small;
        }
      }
      .recover-btn {
        // margin-top: 1em;
        border: none;
        color: #495057;
        display: flex;
        justify-content: center;
        padding: 0.3rem 1em;
        text-align: center;
        display: flex;
        align-items: center;
        background: #ffdc00;
        font-size: $font-size-14;
        border-radius: 0.5em;
      }
      .disconnect-btn {
        margin-top: 1em;
      }
    }
    &.network {
      top: 5.5em;
      right: -0.5em;
      height: 12em;
      width: 15em;
      padding: 1em;
      align-items: center;
      label {
        color: #cdc7c7;
        text-align: start;
        font-size: medium;
        span {
          font-size: small;
        }
      }
      .recover-btn {
        // margin-top: 1em;
        border: none;
        color: #495057;
        display: flex;
        justify-content: center;
        padding: 0.3rem 1em;
        text-align: center;
        display: flex;
        align-items: center;
        background: #ffdc00;
        font-size: $font-size-14;
        border-radius: 0.5em;
      }
      .disconnect-btn {
        margin-top: 1em;
      }
    }
    .disconnect-btn {
      border: none;
      text-transform: capitalize;
      background-color: transparent;
      box-shadow: none;
      color: #6f6e84;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: small;
      svg {
        margin-right: 0.5em;
      }
    }
    .disconnect-btn:hover {
      color: #fa6364;
      svg {
        color: #fa6364 !important;
      }
    }
  }
  .boading-btn {
    border: none;
    font-size: 1rem;
    position: absolute;
    top: 2.5em;
    right: 1em;
    background-color: #ffdc00;
    color: #2a2a2a;
    border-radius: 5em;
    text-transform: capitalize;
    font-weight: bold;
    padding: 0.4em;
    text-align: center;
    display: flex;
    align-items: center;
    button {
      color: #2a2a2a;
    }
  }
}
.trade-announcement-container{
  // position: relative;
  // top: -2em;
  // left: 0;
  .trade-box{
    svg{
      max-width: 6em;
    }
    ._label{
      &.--trade-announcement{
        color: #EDEBEB;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 0.75rem;
      }
    }
  }
}

.common-header-main-desktop {
  display: none;
}

.navbar-container {
  padding: 0.8em 0em 1em 0em !important;
  background: #222222;
  border-radius: 40px 40px 0px 0px;
  label {
    // font-family: $font-merriweather-bold;
    color: #a9a9a9;
    font-size: x-small;
    padding-top: 0.1rem;
  }
}

.fixed-bottom {
  z-index: 3 !important;
}

.modal {
  background: #00000091 !important;
}

.modal-backdrop {
  opacity: 0 !important;
  z-index: 0 !important;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/*!****************
      Element
******************/

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;
  font-family: "cabin";

  display: flex;
  margin: 1em 0 25px;
}

.controls {
  font-size: 1rem;
  display: inline-flex;
  justify-content: space-between;
  background: #2a2a2a;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5em;
  max-width: 400px;
  padding: 0.1em 0.5em;
  margin: unset;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0em;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  min-width: 120px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 600;
  padding: 0.7em;
  transition: color 0.5s ease;
}

.segment.active label {
  color: #ffdc00;
}

.controls::before {
  content: "";
  background: #1a1a1a;
  border-radius: 2em;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  z-index: 0;
}
.desktop-home-container .mobile-version .announcement-container .controls::before {
  min-width: 120px;
}
/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

/*!****************
   Pages Component 
******************/
.maintenance-container{
  padding-top: 2em;
  padding-bottom: 5em;
  z-index: 1;
  height: 65vh;
  .container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .background-image {
    // background-image: url(./assets/images/background/home-page.png);
    position: absolute;
    // width: 100%;
    height: auto;
    z-index: 0;
    left: 0;
    top: -30em;
    // top: 40%;
    // background-size: cover;
    // background-repeat: no-repeat;
    opacity: 0.5;
  }
  img {
  }
  .banner-text-container {
    position: relative;
    // animation: fadeInUp 500ms ease-in-out;
    margin-top: 1em;
    text-align: start;
    width: 19.7em;
    margin-left: auto;
    margin-right: auto;
    .title {
      width: auto;
      padding: 0;
      font-family: "montserrat";
      text-transform: uppercase;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-65%);
      span {
        color: #EDEBEB;
        text-shadow: #000 1px 0 10px;
        width: auto;
        z-index: 1;
        padding: 0;
      }
    }
  }
  ._label{
    font-family: "cabin";
    color: gray;
    line-height: 1.5em;
    &.--title{
      width: 100%;
      padding: 1em;
      padding-top: 2em;
      letter-spacing: 1px;
      font-size: medium;
      color: rgb(240, 160, 0);
      text-transform: uppercase;
    }
    &.--desc{
      font-size: small;
      letter-spacing: 0.5px;
      color: rgba(237, 235, 235, 0.746);
    }
  }
}
@media (min-width: 992px) {
.maintenance-container{
  height: 70vh;
  ._label{
    font-family: "cabin";
    color: gray;
    line-height: 1.5em;
    &.--title{
      width: 100%;
      padding: 1em;
      padding-top: 1em;
      letter-spacing: 1px;
      font-size: 2rem;
      color: rgb(240, 160, 0);
      text-transform: uppercase;
    }
    &.--desc{
      font-size: medium;
      letter-spacing: 0.5px;
      color: rgba(237, 235, 235, 0.746);
    }
  }
}
}

.home-container {
  overflow-x: hidden;
  // background: transparent;
  position: relative;
  z-index: 1;
  padding-bottom: 5em;
  .desktop-version {
    display: none;
  }
  .banner-container {
    padding-top: 2em;
    padding-bottom: 5em;
    z-index: 1;
    // position: relative;
    .background-image {
      // background-image: url(./assets/images/background/home-page.png);
      position: absolute;
      // width: 100%;
      height: auto;
      z-index: 0;
      left: 0;
      top: -30em;
      // top: 40%;
      // background-size: cover;
      // background-repeat: no-repeat;
      opacity: 0.5;
    }
    img {
      animation: fadeInUp 500ms ease-in-out;
    }
    .banner-text-container {
      animation: fadeInUp 500ms ease-in-out;
      margin-top: 1em;
      text-align: start;
      width: 19.7em;
      margin-left: auto;
      margin-right: auto;
      .title {
        width: auto;
        padding: 0;
        font-family: "montserrat";
        text-transform: uppercase;
        z-index: 1;
      }
      .text {
        width: auto;
        z-index: 1;
        font-family: "cabin";
        text-transform: lowercase;
        padding: 0;
      }
    }
  }
  .header-container-home {
    color: #edebeb;
    text-align: end;
    margin-bottom: 1em;
    label {
      font-family: "montserrat";
      font-size: 1.5rem;
      font-weight: 500;
      z-index: 1;
    }
    span {
      font-family: "cabin";
      font-size: 0.875rem;
      z-index: 1;
      color: #9e9e9e;
    }
  }
  .trending-trade-container {
    padding-bottom: 2em;
    padding: 0 0.5em;
    .header-container-home {
      animation: fadeInRight 500ms ease-in-out;
    }
    .trending-card-container {
      color: #edebeb;
      height: 13em;
      overflow: scroll;
      .card-row {
        position: relative;
        .trending-radio {
          align-items: center;
          display: flex;
          margin-right: 2em;
          z-index: 1;
          animation: fadeIn 500ms ease-in-out;
        }
        .line {
          animation: fadeIn 500ms ease-in-out;
          z-index: 1;
          background: #ffdc00;
          position: absolute;
          width: 3em;
          height: 0.1em;
          padding: 0;
          // top: 4.78em;
          top: 4.5em;
          transform: rotate(-90deg);
        }
        .trending-card {
          animation: fadeInLeft 500ms ease-in-out;
          .trending-image {
            padding: 0 0.5em;
            img {
              border-radius: 50%;
              // background: linear-gradient(192.88deg, rgba(24, 24, 24, 0.5) 4.78%, rgba(247, 147, 26, 0.1) 90.43%)
            }
           
          }
          &.green {
            background: linear-gradient(93.2deg, rgba(167, 158, 158, 0.3) 62.39%, rgba(154, 0, 0, 0.4) 115.01%);
          }
          &.red {
            background: linear-gradient(93.2deg, rgba(167, 158, 158, 0.3) 57.19%, rgba(8, 141, 45, 0.4) 115.01%);
          }
          z-index: 1;
          border-radius: 1rem;
          padding: 0.5em;
          display: flex;
          align-items: center;
          margin: 0.5em 0;
          box-shadow: inset 0px 0px 5px rgba(255, 255, 255, 0.25);
          .trending-value {
            .label {
              // justify-content: end;
              font-size: 0.5625em;
              align-items: center;
              display: flex;
              padding: 0;
              color: #9e9e9e;
            }
            .value {
              padding: 0;
              padding-right: 0.5em;
              &.green {
                color: $color-positive;
              }
              &.red {
                color: #fa6364;
              }
              font-size: 0.875rem;
              text-align: end;
              font-weight: bold;
              font-family: "cabin";
              // justify-content: center;
              // display: flex;
            }
          }
        }
      }
      .card-row:last-child {
        .line {
          display: none;
        }
      }
      &.desktop-version {
        display: none;
      }
    }
    .trending-card-container::-webkit-scrollbar {
      display: none !important;
    }
  }
  .home-market-container {
    padding-bottom: 3em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 2em;
    .header-container-home {
      animation: fadeInRight 500ms ease-in-out;
    }
    .market-data-container {
      animation: fadeInUp 500ms ease-in-out;

      background-color: #2a2a2a;
      border-radius: 1.8em;
      padding: 1em 0.5em;
      .market-title {
        font-family: "cabin";
        font-size: 0.875rem;
        color: #9e9e9e;
        padding: 0.5em;
        text-align: center;
        align-items: center;
        line-height: 1em;
      @media (min-width: 992px) {
        padding: 0;
        text-align: unset;
        align-items: unset;
        line-height: unset;
      }
      }
      .market-value {
        font-family: "montserrat";
        font-size: 1rem;
        color: #edebeb;
        font-weight: 600;
      }
      @media (min-width: 992px) {
        .market-value {
          font-size: 1.25rem;
        }
      }
    }
    &.home-partner-container{
      .partner-row{
        margin-top: 1em;
        margin-left: 0!important;
        margin-right: 0!important;
      }
      img{
        margin-left: auto;
        margin-right: auto;
      }
      .col{
        padding: 0;
      }
      ._label{
        width: auto;
        line-height: 1em;
        font-size: 0.563rem;
        color: #9E9E9E;
      }
      @media (min-width: 992px) {
      ._label{
        line-height: 1em;
            font-size: 0.75rem;
            color: #9E9E9E;
      }
    }
    }
  }
  .announcement-container {
    color: #9e9e9e;
    padding-bottom: 0em;
    .controls-container {
      .controls {
        margin: auto;
        width: unset;
      }
    }
    .announcement-content-container {
      animation: fadeInUp 500ms ease-in;
      // padding: 0 1em;
      .swiper {
        .announcement-swiper {
          opacity: 1 !important;
        }
        .swiper-pagination {
          .swiper-pagination-bullet {
            &.swiper-pagination-bullet-active-main {
              background: rgb(240, 160, 0);
              opacity: 1;
            }
            background: white;
            opacity: 0.7;
          }
        }
      }
      .card-container {
        padding: 0.5em 1em;
        .annoucement-card-container {
          font-family: "cabin";
          background: #242628;
          border-radius: 1.5rem;
          padding: 0.7em;
          min-height: 22em;
          // margin: 1em 0;
          .card-img-top {
            border-radius: 1rem;
            max-height: 13em;
            object-fit: contain;
            background-color: #000000;
          }
          .card-body {
            padding: 0;
            padding-top: 0.5em;
            font-size: 0.875rem;
            .people-btn {
              background: #1a1a1a;
              box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.5);
              border-radius: 2em;
              text-transform: lowercase;
              color: #9e9e9e;
              width: auto;
              text-align: start;
              padding: 0.5rem;
              // margin-top: 1em;
              span {
                font-family: "Montserrat";
                // margin-left: 0.5em;
              }
            }
            .time-status {
              text-align: end;
              font-size: 0.75rem;
              svg {
                margin-right: 0.5em;
              }
            }
          }
        }
      }
    }
    // .tab-bar-container{
    //   .active{
    //     color:#FFDC00;
    //     background: #1A1A1A;
    //     border-radius: 2em;
    //     padding: 0.2em 0.5em;
    //   }
    //   align-items: center;
    //   background: #2A2A2A;
    //   margin-left: auto;
    //   margin-right: auto;
    //   margin-bottom: 2em;
    //   width: 17em;
    //   border-radius: 2em;
    //   padding: 0.3em;
    //   text-align: center;
    // }
  }
  .portfolio-container {
    .sub-header-wallet-container {
      position: relative;
      font-family: "Montserrat";
      span {
        color: #7174eb;
        width: auto;
        font-size: 0.75rem;
      }
      label {
        color: #edebeb;
        width: auto;
        text-align: end;
        font-size: 1.25rem;
      }
    }
    .portfolio-wallet-container {
      margin-top: 1em;
      margin-bottom: 3em;
      color: #fefefe;
      text-align: center;
      position: relative;
      font-family: "montserrat";
      .header-wallet {
        font-size: 1rem;
      }
      .price-wallet {
        margin: 1em 0;
        line-height: 1em;
        label {
          font-size: 1.5rem;
          width: 100%;
        }
        span {
          color: #b1b1c9;
          font-size: 0.75rem;
        }
      }
      .wallet-btn {
        color: #edebeb;
        font-family: "cabin";
        font-weight: 600;
        padding: 0.5em 1em;
        font-size: 0.875rem;
        background: transparent;
        border: 1px solid #abaecd;
        border-radius: 0.8rem;
        text-transform: capitalize;
        box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
      }
      .line {
        position: absolute;
        height: 5em;
        width: 0.1em;
        padding: 0;
        background: #63646a;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .portfolio-report-container {
      background: #2a2a2a;
      margin-top: 1em;
      margin-bottom: 1.5em;
      padding: 1em;
      border-radius: 1em;
      .progress-header {
        label {
          white-space: nowrap;
        }
        .title {
          text-align: start;
          color: #fefefe;
        }
        .montly-text {
          text-align: center;
          label {
            font-size: 0.875rem;
          }
        }
        .profit {
          text-align: end;
          // color: $color-positive;
          &.green {
            color: #59d08e;
            // background: rgba(255, 255, 255, 0.01);
            // box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25), inset 3px 0px 35px rgba(89, 208, 142, 0.4);
          }
          &.red {
            color: #fa6364;
            // background: rgba(255, 255, 255, 0.01);
            // box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25), inset 3px 0px 35px rgba(221, 0, 0, 0.4);
          }
        }
      }
      .progress-row {
        overflow: hidden;
        padding: 1em 0;
        height: 12em;
        max-width: 25em;
        margin-left: auto;
        margin-right: auto;
        .report-note-container {
          font-size: 0.5rem;
        }
        .progress-bar-report {
          position: relative;
          transform: rotate(-90deg);
          // height: 12em;
          .progress-bar-col {
            position: absolute;
            left: 80%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            .progress {
              width: 10em;
              background: #736747;
              border-radius: 0.5em;
              .progress-bar {
                background: #f0a000;
                border-radius: 0.5em;
              }
            }
            label {
              margin-right: 0.8em;
              transform: rotate(90deg);
              color: #fefefe;
            }
          }
        }
      }
      .open-position-container {
        background: #1a1a1a;
        color: #fefefe;
        border-radius: 0.5em;
        align-items: center;
        margin: 1em 0;
        padding: 0.7em 0.5rem;
        box-shadow: inset 0px 4px 30px rgba(0, 0, 0, 0.25);
        .rate {
          text-align: center;
          color: #59d08e;
          font-size: 0.75rem;
          &.--long {
            color: #59d08e;
            padding: 0em 0.5em;
            border-radius: 0.2rem;
          }
          &.--short {
            color: #FA6364;
            padding: 0em 0.5em;
            border-radius: 0.2rem;
          }
        }
        .detail {
          font-size: 0.5625em;
          color: rgba(144, 145, 160, 0.5);
        }
        .status {
          font-size: 0.5625em;
          color: #9091a0;
          text-align: end;
          .type {
            color: #27293e;
            justify-content: end;
            text-transform: capitalize;
            text-align: end;
            label{
              padding: 0;
            }
            &.--long {
              color: #59d08e;
              width: auto;
              padding: 0em 0.5em;
              border-radius: 0.2rem;
            }
            &.--short {
              color: #FA6364;
              width: auto;
              padding: 0em 0.5em;
              border-radius: 0.2rem;
            }
          }
          .price {
            label {
              padding: 0 0.5em;
            }
          }
          .size {
            label {
              padding: 0 0.5em;
            }
          }
        }
        .coming-soon-label {
          text-align: center;
          background: linear-gradient(270deg, #f0a000 6.9%, #ffdc00 87.53%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: bold;
          font-family: "Montserrat";
        }
      }
    }
    .portfolio-order-container {
      margin-top: 1em;
      margin-bottom: 1.5em;
      // padding: 1em;
      .open-position-container {
        background: #2a2a2a;
        color: #fefefe;
        border-radius: 0.5em;
        align-items: center;
        margin: 1em 0;
        padding: 0.7em 0.5rem;
        box-shadow: inset 0px 4px 30px rgba(0, 0, 0, 0.25);
        .rate {
          text-align: center;
          color: #59d08e;
          font-size: 0.75rem;
        }
        .detail {
          font-size: 0.5625em;
          color: rgba(144, 145, 160, 0.5);
        }
        .status {
          font-size: 0.5625em;
          color: #9091a0;
          text-align: end;
          .type {
            color: #27293e;
            justify-content: end;
            text-transform: capitalize;
            text-align: end;
            label{
              padding: 0;
            }
            &.--long {
              color: #59d08e;
              width: auto;
              padding: 0em 0.5em;
              border-radius: 0.2rem;
            }
            &.--short {
              color: #FA6364;
              width: auto;
              padding: 0em 0.5em;
              border-radius: 0.2rem;
            }
          }
          .price {
            label {
              padding: 0 0.5em;
            }
          }
          .size {
            label {
              padding: 0 0.5em;
            }
          }
        }
        .coming-soon-label {
          text-align: center;
          background: linear-gradient(270deg, #f0a000 6.9%, #ffdc00 87.53%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: bold;
          font-family: "Montserrat";
        }
      }
    }
  }
}

// .home-container::-webkit-scrollbar {
//   display: none !important;
// }


.video-container{
  margin-bottom: 1em;
  border-radius: 1em;
  overflow: hidden;
  &.special{
    height: 14em;
    iframe{
      width: 100%;
      height: 14em;
      @media only screen and (min-width: 992px) {
        height: 17em;
      }
    }
    .video-react{
      padding-top: 22.5em !important;
      .video-react-video{
        height: 23em;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    &.special{
      height: 17em;
      .video-react{
        padding-top: 27.5em !important;
        .video-react-video{
          height: 28em;
        }
      }
    }
  }
  .video-react-poster{
    right: unset !important;
  }
  &.disable{
    height: 12.5em;
    .video-react{
      .video-react-big-play-button{
        display: none;
      }
      .video-react-control-bar{
        display: none;
      }
      .video-react-video{
        height: 20em;
        border-radius: 1em;
        overflow: hidden;
      }
    }
  }
  .video-react{
    padding-top: 27.5em !important;
    .video-react-big-play-button{
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.announcement-detail-modal {
  width: auto;
  .modal-content{
    overflow: hidden;
    border-radius: 1rem;
    background: #1A1A1A;
  }
  .modal-body {
    height: 100vh;
    // overflow: hidden !important;
    background: #1a1a1a;
  }
  .modal-body::webkit-scrollbar {
    display: none !important;
  }
  .common-header-main {
    position: absolute;
    align-items: end;
    padding: 0 1em;
  }
  .content-container {
    animation: fadeInUp 500ms ease-in;
    background: #1a1a1a;
    border-radius: 5em 5em 0 0;
    position: relative;
    margin-top: 9em;
    .top-position {
      .video-container{
        iframe{
          width: 100%;
          height: 16em;
          border-radius: 1em;
        }
      }
      position: absolute;
      top: -10%;
      left: 10%;
      padding-top: 6em;
      padding-left: 2em;
      padding-right: 2em;
      transform: translate(-10%);
      width: 100%;
      .paragraph {
        margin-top: 3em;
        color: #9e9e9e;
        font-size: 0.875rem;
      }
    }
    .card-container {
      color: #edebeb;
      background: #2a2a2a;
      padding: 1em;
      border-radius: 1em;
      font-family: "cabin";
      .text {
        // padding-bottom: 1em;
        line-height: 1.5em;
        font-size: 1.125rem;
      }
      .content-author {
        margin: 0.5em 0;
        margin-bottom: 0;
        img {
          margin-right: 0.5em;
        }
        .days {
          color: #9091a0;
          font-size: 0.75rem;
        }
      }
      .line {
        margin: 1em 0;
        border-bottom: 1px solid rgba(117, 118, 131, 0.6);
      }
    }
  }
  .modal-header-image {
    background-position-x: center;
    height: 20em;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.profile-modal-container {
  position: relative;
  animation: fadeInUp 500ms ease-in-out;
  // max-width: 25em !important;
  width: 100%;
  top: 30%;
  // left: 50%;
  // transform: translate(-50%, -50%) !important;
  outline: none;
  @media only screen and (min-width: 992px) {
    top: 50%;
    .modal-body{
      padding-bottom: 5rem !important;
    }
  }
  .modal-body {
    padding-bottom: 23em;
    font-family: 'Cabin';
    color: #9E9E9E;
    margin: 1em;
    border-radius: 2em;
    background: #1A1A1A;
    min-height: 27em;
    font-size: 0.875rem;
    .wallet-options{
      color: #9E9E9E;
      width: 100%;
      height: 3em;
      border: 0.1em solid #9E9E9E;
      box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
      border-radius: 12px;
      background: linear-gradient(314.37deg, rgba(255, 255, 255, 0) 8.93%, #9E9E9E 135.23%);
    }
    ._label{
      &.--status{
        font-size: 0.875rem;
        color: #FA6364;
        text-transform: uppercase;
      }
    }
    input{
      width: 100%;
      background-color: transparent;
      border: 0.1em solid #9E9E9E;
      border-radius: 0.8em;
      padding: 0.5em;
      color: #fefefe;
      outline: none;
    }
    .text-white{
      color: #fefefe;
      font-size: 1rem;
    }
    .profile-modal-row:first-child{
      color: #fefefe;
      margin-top: 0em;
      font-size: 1.5rem;
    }
    .profile-modal-row{
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
    input[type="number"]::-webkit-outer-spin-button, 
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .enter-amount-button{
      width: 100%;
      font-size: 1.125rem;
      background-color: transparent;
      border: 0.05em solid #9E9E9E;
      color: #EDEBEB;
      border-radius: 0.5em;
      padding: .35em 1em;
    }
    .enter-amount-button:disabled{
      color: #edebeb63;
      cursor: no-drop;
    }
    .max-button{
      width: 100%;
      font-size: 1.125rem;
      background: #2A2A2A;
      border: 0.05em solid #9E9E9E;
      color: #9E9E9E;
      border-radius: 0.5em;
      padding: .35em 1em;
    }
  }
}

@media only screen and (min-width: 992px) {
  .profile-modal-container {
    position: absolute;
    animation: fadeInUp 500ms ease-in-out;
    max-width: 25em !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    outline: none;
  }
}

.trade-order-container{
  outline: none;
  animation: fadeInUp 500ms ease-in-out;
  // transform: translate(0, -50%)!important;
  border-radius: 0.5rem;
  position: absolute;
  // top: 50%;
  // left: 50%;
  z-index: 999999 !important;
  background: #1A1A1A;
  box-shadow: 0 2px 15px -3px rgba(0,0,0,.07), 0 10px 20px -2px rgba(0,0,0,.04);
  &.--cancel-order{
    // transform: translate(-50%, -50%)!important;
    border-radius: 0.5rem;
    position: absolute;
    // top: 50%;
    // left: 50%;
    z-index: 999999 !important;
    background: #1A1A1A;
    box-shadow: 0 2px 15px -3px rgba(0,0,0,.07), 0 10px 20px -2px rgba(0,0,0,.04);
    .card-header{
      border-bottom: 2px solid #58585843;
      ._label{
        width: 100%;
        &.--symbol{
          font-family: "cabin";
          color: #EBEBEB;
          font-weight: bold;
          font-size: 1.25rem;

        }
        &.--order-type{
          font-family: "cabin";
          font-weight: bold;
          text-transform: uppercase;
          &.--long{
            color: rgb(89, 208, 142);
          }
          &.--short{
            color: rgb(250, 99, 100);
          }
        }
      }
      .round-btn {
        background: #484848;
        width: 2em;
        height: 2em;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 50%;
        // animation: moveToleft 0.8s ease-in;
      }
      .back-btn {
        display: flex;
        justify-content: end;
    
        &.after {
          .round-btn {
            overflow: hidden;
            animation: moveToRight 0.5s ease-in;
            background: #484848;
            width: 100%;
            height: 2.5em;
            justify-content: end;
            padding: 1em;
            display: flex;
            align-items: center;
            border-radius: 5em;
          }
        }
      }
    }
    .modal-body{
      padding: 0;
      .order-detail-container{
        padding: 1em;
        .--row-special{
          margin-top: 0.6em;
          margin-bottom: 0em !important;
          margin-left: 0em !important;
          margin-right: 0em !important;
        }
        ._label{
          width: 100%;
          font-family: "cabin";
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          &.--title{
            font-size: 1rem;
            color: #6F7283;
        
          }
          &.--title-value{
            color: #EBEBEB;
            text-transform: capitalize;
            &.--long{
              color: rgb(89, 208, 142);
            }
            &.--short{
              color: rgb(250, 99, 100);
            }
          }
          &.--title-value-details{
            justify-content: end;
            align-items: center;
            display: flex;
            color: #EBEBEB;
            text-transform: normal;
            &.--long{
              color: rgb(89, 208, 142);
            }
            &.--short{
              color: rgb(250, 99, 100);
            }
            &.--filled{
              color: rgb(89, 208, 142);
            }
            &.--cancel{
              color: rgb(250, 99, 100);
            }
          }
        }
        ._checkbox{
          font-family: "cabin";
          margin-top: 1em;
          margin-left: 0;
          margin-right: 0;
          display: flex;
          align-items: center;
          justify-content: start;
          &.--promp-window{
            color: #6F7283;
            font-size: 0.875rem;
            padding: 0;
            input{
              margin-right: 0.5em;
              accent-color: #FFDC00;
            }
          }
        }
        ._button{
          display: flex;
          justify-content: center;
          align-items: center;
          &.--confirm{
            width: 20em;
            padding: 0.5em;
            background-color: transparent;
            border: 1px solid #ABAECD;
            color: #EDEBEB;
            box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
            border-radius: 12px;
          }
          &.--confirm:disabled{
            width: 20em;
            padding: 0.5em;
            background-color: transparent;
            border: 1px solid #ABAECD;
            color: #edebeb63;
            box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
            border-radius: 12px;
          }
        }
      }
      .order-profit-container{
        border-radius: 2em;
        background: #1A1A1A;
        color: #fefefe;
        padding: 1em;
        // min-height: 50em;
        font-family: 'cabin';
        .line-container{
          &.--grey{
            margin: 1.5em 0em;
            background-color: #9e9e9e17;
            width: 100%;
            height: 0.1em;
          }
        }
        ._input{
          outline: none;
          &.--trade-modal{
            margin-top: 1em;
            text-align: center;
            width: 100%;
            background-color: transparent;
            border: 0.1em solid #ABAECD;
            border-radius: 0.5em;
            padding: 0.5em;
            color: #9E9E9E;
          }
          &.--trade-modal:disabled{
            text-align: center;
            width: 100%;
            background-color: transparent;
            border: 0.1em solid rgba(171, 174, 205, 0.24);
            border-radius: 0.5em;
            padding: 0.5em;
          }
          &.--trade-modal::placeholder{
            color: rgba(158, 158, 158, 0.24);
          }
        }
        input[type="number"]::-webkit-outer-spin-button, 
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        .--border-special{
          padding-right: 0.6em !important;
          padding-left: 0em !important;
        }
        .--row-special{
          margin-top: 0.6em;
          margin-bottom: 0em !important;
          margin-left: 0em !important;
          margin-right: 0em !important;
        }
        ._label{
          display: flex;
          align-items: center;
          &.--symbol-title{
            font-size: 1.125rem;
            color: #9E9E9E;
          }
          &.--status{
            font-size: 0.875rem;
            color: #FA6364;
            text-transform: uppercase;
          }
          &.--title-symbol{
            color: #FEFEFE;
            font-size: 1.5rem;
            justify-content: center;
          }
          &.--main-title{
            font-size: 1.5rem;
            color: #FEFEFE;
          }
          &.--chain-title{
            font-size: 1.125rem;
            width: auto;
            color: #9E9E9E;
          }
          &.--price-title{
            font-size: 1.125rem;
            width: auto;
            color: #EDEBEB;
          }
          &.--small-title{
            color: #9E9E9E;
            font-size: 0.75rem;
    
          }
          &.--white-small-text{
            color: #EDEBEB;
            font-size: 0.75rem;
          }
          &.--profit{
            width: auto;
            margin-right: 1em;
            font-size: 1rem;
            color: #FEFEFE;
            background: #1A1A1A;
            border: 1px solid #59D08E;
            border-radius: 0.8em;
            padding: 0.5em 0.8em;
            box-shadow: inset -2px -2px 25px rgba(89, 208, 142, 0.31);
            color: #59D08E;
          }
          &.--loss{
            width: auto;
            font-size: 1rem;
            color: #FEFEFE;
            background: #1A1A1A;
            border: 1px solid #FA6364;
            border-radius: 0.8em;
            padding: 0.5em 0.8em;
            box-shadow: inset -2px -2px 25px rgba(250, 99, 100, 0.25);
            color: #FA6364;
          }
          &.--note-text{
            color: #9091A0;
            font-size: 0.75rem;
            line-height: 1.2em;
          }
          &.--border-text-active{
            justify-content: center;
            font-size: 1rem;
            color: #FEFEFE;
            background: #484848;
            border: 1px solid #ABAECD;
            border-radius: 0.5em;
            padding: 0.5em 1.8em;
            box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
            margin-top: 1em;
    
          }
          &.--levarage-title{
            width: auto;
            border-radius: 0.5em;
            padding: 0.5em;
            background: #2A2A2A;
            font-size: 0.75rem;
          }
          &.--border-text{
            justify-content: center;
            font-size: 1rem;
            color: #FEFEFE;
            border: 0.1em solid rgba(171, 174, 205, 0.24);
            border-radius: 0.5em;
            margin-top: 1em;
            padding: 0.5em 1.8em;
            white-space: nowrap;
          }
          &.--total-title{
            width: 100%;
            font-size: 0.875rem;
            color: #9E9E9E;
            svg{
              margin-left: 0.5em;
            }
          }
          &.--amount{
            width: 100%;
            font-size: 0.875rem;
            color:#9091A0;
          }
        }
        ._button{
          display: flex;
          justify-content: center;
          align-items: center;
          &.--confirm{
            width: 20em;
            padding: 0.5em;
            background-color: transparent;
            border: 1px solid #ABAECD;
            color: #EDEBEB;
            box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
            border-radius: 12px;
          }
          &.--back-button{
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  &.modal-static{
    transform: scale(1.02)!important;
  }
  // .modal-dialog{
  //   transform: translate(-50%, -50%)!important;
  //   position: absolute;
  //   width: auto;
  //   top: 50%;
  //   left: 50%;
  //   margin: 0;
  //   pointer-events: none;
  //   .modal-content{
  //     background: #1A1A1A;
      .card-header{
        border-bottom: 2px solid #58585843;
        ._label{
          width: 100%;
          &.--symbol{
            font-family: "cabin";
            color: #EBEBEB;
            font-weight: bold;
            font-size: 1.25rem;
            display: flex;
            align-items: center;
            div{
              align-items: center;
              display: flex;
              padding-left: 0.5em;
              svg{
                color: #484848;
              }
              svg:hover{
                color: rgb(240, 160, 0);
                cursor: pointer;
              }
            }
          }
          &.--order-type{
            font-family: "cabin";
            font-weight: bold;
            text-transform: uppercase;
            &.--long{
              color: rgb(89, 208, 142);
            }
            &.--short{
              color: rgb(250, 99, 100);
            }
          }
        }
        .round-btn {
          background: #484848;
          width: 2em;
          height: 2em;
          justify-content: center;
          display: flex;
          align-items: center;
          border-radius: 50%;
          // animation: moveToleft 0.8s ease-in;
        }
        .back-btn {
          display: flex;
          justify-content: end;
      
          &.after {
            .round-btn {
              overflow: hidden;
              animation: moveToRight 0.5s ease-in;
              background: #484848;
              width: 100%;
              height: 2.5em;
              justify-content: end;
              padding: 1em;
              display: flex;
              align-items: center;
              border-radius: 5em;
            }
          }
        }
      }
      .modal-body{
        padding: 0;
        max-height: 30em;
        overflow: scroll;
        @media only screen and (max-width: 991px) {
          max-height: 25em;
          overflow: scroll;
        }
        .order-detail-container{
          padding-left: 1em;
          padding-right: 1em;
          padding-top: 1em;
          padding-bottom: 1em;
            @media only screen and (max-width: 991px) {
              padding-left: 1em;
              padding-right: 1em;
              padding-top: 1em;
              padding-bottom: 3em;
            }
          .--row-special{
            margin-top: 0.6em;
            margin-bottom: 0em !important;
            margin-left: 0em !important;
            margin-right: 0em !important;
          }
          ._label{
            width: 100%;
            font-family: "cabin";
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            &.--title{
              font-size: 1rem;
              color: #6F7283;
          
            }
            &.--title-value{
              color: #EBEBEB;
              text-transform: capitalize;
              &.--long{
                color: rgb(89, 208, 142);
              }
              &.--short{
                color: rgb(250, 99, 100);
              }
            }
            &.--title-value-details{
              justify-content: end;
              align-items: center;
              display: flex;
              color: #EBEBEB;
              text-transform: normal;
              &.--long{
                color: rgb(89, 208, 142);
              }
              &.--short{
                color: rgb(250, 99, 100);
              }
              &.--filled{
                color: rgb(89, 208, 142);
              }
              &.--cancel{
                color: rgb(250, 99, 100);
              }
            }
          }
          ._checkbox{
            font-family: "cabin";
            margin-top: 1em;
            margin-left: 0;
            margin-right: 0;
            display: flex;
            align-items: center;
            justify-content: start;
            &.--promp-window{
              color: #6F7283;
              font-size: 0.875rem;
              padding: 0;
              input{
                margin-right: 0.5em;
                accent-color: #FFDC00;
              }
            }
          }
          ._button{
            display: flex;
            justify-content: center;
            align-items: center;
            &.--confirm{
              width: 20em;
              padding: 0.5em;
              background-color: transparent;
              border: 1px solid #ABAECD;
              color: #EDEBEB;
              box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
              border-radius: 12px;
            }
            &.--confirm:disabled{
              width: 20em;
              padding: 0.5em;
              background-color: transparent;
              border: 1px solid #ABAECD;
              color: #edebeb63;
              box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
              border-radius: 12px;
            }
          }
        }
        .order-profit-container{
          border-radius: 2em;
          background: #1A1A1A;
          color: #fefefe;
          padding: 1em;
          // min-height: 50em;
          font-family: 'cabin';
          .line-container{
            &.--grey{
              margin: 1.5em 0em;
              background-color: #9e9e9e17;
              width: 100%;
              height: 0.1em;
            }
          }
          ._input{
            outline: none;
            &.--trade-modal{
              margin-top: 1em;
              text-align: center;
              width: 100%;
              background-color: transparent;
              border: 0.1em solid #ABAECD;
              border-radius: 0.5em;
              padding: 0.5em;
              color: #9E9E9E;
            }
            &.--trade-modal:disabled{
              text-align: center;
              width: 100%;
              background-color: transparent;
              border: 0.1em solid rgba(171, 174, 205, 0.24);
              border-radius: 0.5em;
              padding: 0.5em;
            }
            &.--trade-modal::placeholder{
              color: rgba(158, 158, 158, 0.24);
            }
          }
          input[type="number"]::-webkit-outer-spin-button, 
          input[type="number"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
          }
          .--border-special{
            padding-right: 0.6em !important;
            padding-left: 0em !important;
          }
          .--row-special{
            margin-top: 0.6em;
            margin-bottom: 0em !important;
            margin-left: 0em !important;
            margin-right: 0em !important;
          }
          ._label{
            display: flex;
            align-items: center;
            &.--symbol-title{
              font-size: 1.125rem;
              color: #9E9E9E;
            }
            &.--status{
              font-size: 0.875rem;
              color: #FA6364;
              text-transform: uppercase;
            }
            &.--title-symbol{
              color: #FEFEFE;
              font-size: 1.5rem;
              justify-content: center;
            }
            &.--main-title{
              font-size: 1.5rem;
              color: #FEFEFE;
            }
            &.--chain-title{
              font-size: 1.125rem;
              width: auto;
              color: #9E9E9E;
            }
            &.--price-title{
              font-size: 1.125rem;
              width: auto;
              color: #EDEBEB;
            }
            &.--small-title{
              color: #9E9E9E;
              font-size: 0.75rem;
      
            }
            &.--white-small-text{
              color: #EDEBEB;
              font-size: 0.75rem;
            }
            &.--profit{
              width: auto;
              margin-right: 1em;
              font-size: 1rem;
              color: #FEFEFE;
              background: #1A1A1A;
              border: 1px solid #59D08E;
              border-radius: 0.8em;
              padding: 0.5em 0.8em;
              box-shadow: inset -2px -2px 25px rgba(89, 208, 142, 0.31);
              color: #59D08E;
            }
            &.--loss{
              width: auto;
              font-size: 1rem;
              color: #FEFEFE;
              background: #1A1A1A;
              border: 1px solid #FA6364;
              border-radius: 0.8em;
              padding: 0.5em 0.8em;
              box-shadow: inset -2px -2px 25px rgba(250, 99, 100, 0.25);
              color: #FA6364;
            }
            &.--note-text{
              color: #9091A0;
              font-size: 0.75rem;
              line-height: 1.2em;
            }
            &.--border-text-active{
              justify-content: center;
              font-size: 1rem;
              color: #FEFEFE;
              background: #484848;
              border: 1px solid #ABAECD;
              border-radius: 0.5em;
              padding: 0.5em 1.8em;
              box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
              margin-top: 1em;
              white-space: nowrap;
            }
            &.--levarage-title{
              width: auto;
              border-radius: 0.5em;
              padding: 0.5em;
              background: #2A2A2A;
              font-size: 0.75rem;
            }
            &.--border-text{
              justify-content: center;
              font-size: 1rem;
              color: #FEFEFE;
              border: 0.1em solid rgba(171, 174, 205, 0.24);
              border-radius: 0.5em;
              margin-top: 1em;
              padding: 0.5em 1.8em;
              white-space: nowrap;
            }
            &.--total-title{
              width: 100%;
              font-size: 0.875rem;
              color: #9E9E9E;
              svg{
                margin-left: 0.5em;
              }
            }
            &.--amount{
              width: 100%;
              font-size: 0.875rem;
              color:#9091A0;
            }
          }
          ._button{
            display: flex;
            justify-content: center;
            align-items: center;
            &.--confirm{
              width: 20em;
              padding: 0.5em;
              background-color: transparent;
              border: 1px solid #ABAECD;
              color: #EDEBEB;
              box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
              border-radius: 12px;
            }
            &.--back-button{
              position: absolute;
              right: 0;
            }
          }
        }
      }
      .modal-body::-webkit-scrollbar {
        display: none !important;
      }
      .modal-footer{
        border-top: 2px solid #58585843;
        padding: 1.5em;
        ._button{
          display: flex;
          justify-content: center;
          align-items: center;
          word-break: break-word;
          &.--confirm{
            width: 100%;
            padding: 0.5em;
            background-color: transparent;
            border: 1px solid #ABAECD;
            color: #EDEBEB;
            box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
            border-radius: 12px;
          }
          &.--back-button{
            position: absolute;
            right: 0;
          }
        }
      }
  //   }
  // }
  // @media only screen and (min-width: 992px) {
  // .modal-dialog{
  //   transform: translate(-50%, -50%) !important;
  //   position: absolute;
  //   width: auto;
  //   top: 50%;
  //   left: 50%;
  //   margin: 0;
  //   pointer-events: none;
  // }
  // }
}

.trade-modal-container{
  position: absolute;
  animation: fadeInUp 500ms ease-in-out;
  border-radius: 2em 2em 0em 0em;
  width: 100%;
  bottom: 0 !important;
  margin: 0 !important;
  inset: 10% auto 50% !important;
  margin-right: 0 !important;
  transform: none !important;
  outline: none;
  &.--close-position,&.--levarage{
    position: absolute;
    animation: fadeInUp 500ms ease-in-out;
    border-radius: 2em;
    width: 100%;
    bottom: 0 !important;
    margin: 0 !important;
    padding: 1em;
    inset: 30% auto 50% !important;
    margin-right: 0 !important;
    transform: none !important;
    .modal-body {
      position: relative;
      min-height: 15em;
      border-radius: 2em;
      .leverage-container{
        margin-top: 2em;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      ._label{
        &.--title{
          text-align: center;
          font-size: 14px;
          color: #FEFEFE;
        }
        &.--btn-leverage-active{
          background: #FFDC00;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.5em;
          width: 30px;
          height: 30px;
          font-size: 9px;
          color: #393939;
        }
        &.--btn-leverage-active:last-child{
          background: #FFDC00;
          border: 2px solid #F0A000;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.5em;
          width: 30px;
          height: 30px;
          font-size: 14px;
          color: #393939;
        }
        &.--btn-leverage{
          background: #9E9E9E;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.5em;
          width: 30px;
          height: 30px;
          font-size: 14px;
          color: #393939;
        }
      }
      ._input{
        background: #2A2A2A;
        padding: 0.2em 0.5em;
        border-radius: 0.5em;
        &.--trigger-price{
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 2em;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          input{
            font-size: 14px;
            padding: 1em;
            outline: none;
            width: 100%;
            text-align: center;
            background-color: transparent;
            border: none;
            color: #EDEBEB;
          }
          input[type="number"]::-webkit-outer-spin-button, 
          input[type="number"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
          }
        }
      }
    }
  }
  .modal-body {
    border-radius: 2em 2em 0 0;
    background: #1A1A1A;
    color: #fefefe;
    min-height: 50em;
    font-family: 'cabin';
    .line-container{
      &.--grey{
        margin: 1.5em 0em;
        background-color: #9e9e9e17;
        width: 100%;
        height: 0.1em;
      }
    }
    ._input{
      &.--trade-modal{
        margin-top: 1em;
        text-align: center;
        width: 100%;
        background-color: transparent;
        border: 0.1em solid #ABAECD;
        border-radius: 0.5em;
        padding: 0.5em;
        color: #9E9E9E;
      }
      &.--trade-modal:disabled{
        text-align: center;
        width: 100%;
        background-color: transparent;
        border: 0.1em solid rgba(171, 174, 205, 0.24);
        border-radius: 0.5em;
        padding: 0.5em;
      }
      &.--trade-modal::placeholder{
        color: rgba(158, 158, 158, 0.24);
      }
    }
    .--border-special{
      padding-right: 0.6em !important;
      padding-left: 0em !important;
    }
    .--row-special{
      margin-top: 0.6em;
      margin-bottom: 0em !important;
      margin-left: 0em !important;
      margin-right: 0em !important;
    }
    ._label{
      display: flex;
      align-items: center;
      &.--title-symbol{
        color: #FEFEFE;
        font-size: 1.5rem;
        justify-content: center;
      }
      &.--main-title{
        font-size: 1.5rem;
        color: #FEFEFE;
      }
      &.--chain-title{
        font-size: 1.125rem;
        width: auto;
        color: #9E9E9E;
      }
      &.--price-title{
        font-size: 1.125rem;
        width: auto;
        color: #EDEBEB;
      }
      &.--small-title{
        color: #9E9E9E;
        font-size: 0.75rem;

      }
      &.--white-small-text{
        color: #EDEBEB;
        font-size: 0.75rem;
      }
      &.--profit{
        width: auto;
        margin-right: 1em;
        font-size: 1rem;
        color: #FEFEFE;
        background: #1A1A1A;
        border: 1px solid #59D08E;
        border-radius: 0.8em;
        padding: 0.5em 0.8em;
        box-shadow: inset -2px -2px 25px rgba(89, 208, 142, 0.31);
        color: #59D08E;
      }
      &.--loss{
        width: auto;
        font-size: 1rem;
        color: #FEFEFE;
        background: #1A1A1A;
        border: 1px solid #FA6364;
        border-radius: 0.8em;
        padding: 0.5em 0.8em;
        box-shadow: inset -2px -2px 25px rgba(250, 99, 100, 0.25);
        color: #FA6364;
      }
      &.--note-text{
        color: #9091A0;
        font-size: 0.75rem;
        line-height: 1.2em;
      }
      &.--border-text-active{
        justify-content: center;
        font-size: 1rem;
        color: #FEFEFE;
        background: #484848;
        border: 1px solid #ABAECD;
        border-radius: 0.5em;
        padding: 0.5em 0.8em;
        box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
        margin-top: 1em;

      }
      &.--levarage-title{
        width: auto;
        border-radius: 0.5em;
        padding: 0.5em;
        background: #2A2A2A;
        font-size: 0.75rem;
      }
      &.--border-text{
        justify-content: center;
        font-size: 1rem;
        color: #FEFEFE;
        border: 0.1em solid rgba(171, 174, 205, 0.24);
        border-radius: 0.5em;
        margin-top: 1em;
        padding: 0.5em 0.8em
      }
      &.--total-title{
        width: 100%;
        font-size: 0.875rem;
        color: #9E9E9E;
        svg{
          margin-left: 0.5em;
        }
      }
      &.--amount{
        width: 100%;
        font-size: 0.875rem;
        color:#9091A0;
      }
    }
    ._button{
      display: flex;
      justify-content: center;
      align-items: center;
      &.--confirm{
        width: 20em;
        padding: 0.5em;
        background-color: transparent;
        border: 1px solid #ABAECD;
        color: #EDEBEB;
        box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
        border-radius: 12px;
      }
      &.--back-button{
        position: absolute;
        right: 0;
      }
    }
  }
}


.wallet-connect-container {
  position: absolute;
  animation: fadeInUp 500ms ease-in-out;
  border-radius: 2em 2em 0em 0em;
  width: 100%;
  bottom: 0 !important;
  margin: 0 !important;
  inset: 35% auto 50% !important;
  margin-right: 0 !important;
  transform: none !important;
  @media only screen and (min-width: 576px) {
    width: auto;
    bottom: unset !important;
    inset: unset !important;
    position: relative;
    animation: fadeInUp 500ms ease-in-out;
    width: 25em !important;
  }
  .modal-body {
    @media only screen and (min-width: 576px) {
      border-radius: 2em;
    }
    border-radius: 2em 2em 0 0;
    background: #1a1a1a;
    color: #fefefe;
    min-height: 35em;
    .connect-wallet {
    }
    .header-title {
      font-size: 1.5rem;
    }
    .header-desc {
      font-size: 1.125rem;
      color: #9e9e9e;
      line-height: 1.2em;
      padding: 0.5em 0;
      margin-top: 1em;
    }
    .wallet-connect-btn {
      background: #2a2a2a;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0.8em;
      margin: 1em 0;
      padding: 0.5em;
      align-items: center;
    }
    .verify-containet {
      color: #9e9e9e;
      align-items: center;
      // margin: 1em 0;
      padding: 1em 0;
      line-height: 1.2em;
      &.active {
        .title {
          color: #fefefe;
        }
      }
      .round-btn {
        &.active {
          background: rgba(72, 72, 72, 0.4);
        }
        background: #484848;
        height: 3em;
        width: 3em;
        border-radius: 2em;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .title {
        font-size: 1.1875rem;
        width: 100%;
      }
      .desc {
        font-size: 1.125rem;
      }
    }
    .remember-container {
      margin: 1.5em 0;
      align-items: center;
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      input:checked + .slider {
        background-color: #f0a000;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px #f0a000;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      .slider.round:before {
        border-radius: 50%;
      }
    }
    .send-request-btn {
      width: 20em;
      margin-top: 1em;
      margin-bottom: 2em;
      margin-left: auto;
      margin-right: auto;
      button {
        background: transparent;
        border: 0.05em solid #abaecd;
        border-radius: 0.6em;
        font-size: 1.25rem;
        color: #edebeb;
        text-transform: none;
        padding: 0.2em 1em;
      }
    }
    .connecting-container {
      .desc {
        margin-top: 1em;
        color: #9e9e9e;
        font-size: 1.125rem;
        line-height: 1.3em;
      }
      .image {
        display: flex;
        justify-content: center;
        padding: 1em;
      }
      .button {
        .live-chat {
          color: #9e9e9e;
          opacity: 0.3;
          img {
            margin-right: 0.5em;
          }
        }
        .try-again {
          color: #7174eb;
        }
      }
    }
    .disconnect-btn-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .disconnect-btn {
      // position: absolute;
      bottom: 5em;
      right: 2em;
      border: none;
      text-transform: capitalize;
      background-color: transparent;
      box-shadow: none;
      color: #6f6e84;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: medium;
      svg {
        margin-right: 0.5em;
      }
    }
    .disconnect-btn:hover {
      color: #fa6364;
      svg {
        color: #fa6364 !important;
      }
    }
    .disconnect-btn {
      // position: absolute;
      bottom: 5em;
      right: 2em;
      border: none;
      text-transform: capitalize;
      background-color: transparent;
      box-shadow: none;
      color: #6f6e84;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      svg {
        margin-right: 0.5em;
      }
    }
  }
  .modal-body:focus-visible {
    border-color: red;
  }
}
.wallet-connect-container:focus-visible {
  outline: none;
}
.safari-only {
  transform: translate(-50%, -7%) !important;
}
.ReactModalPortal {
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 9998;
  }
}
.ReactModal__Overlay--after-open{
  align-items: center;
  justify-content: center;
  display: flex;
}

@media only screen and (min-width: 576px) {
  .ReactModalPortal {
    .ReactModal__Overlay {
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.75) !important;
      z-index: 9998;
      // .wallet-connect-container,
      .rebate-history-container{
        position: relative;
        animation: fadeInUp 500ms ease-in-out;
        width: 25em !important;
        transform: translate(-50%, -50%) !important;
        inset: 50% auto auto 50% !important;
        .modal-body {
          border-radius: 2em !important;
        }
      }
      .trade-modal-container {
        position: relative;
        animation: fadeInUp 500ms ease-in-out;
        width: 25em !important;
        transform: translate(-50%, -50%) !important;
        inset: 50% auto auto 50% !important;
        .modal-body {
          border-radius: 2em !important;
          // min-height: 45em;
        }
        &.--close-position{
          position: relative;
          animation: fadeInUp 500ms ease-in-out;
          width: 25em !important;
          transform: translate(-50%, -50%) !important;
          inset: 50% auto auto 50% !important;
          .modal-body {
            border-radius: 2em !important;
            // min-height: 20em;
          }
        }
      }
    }
  }
}
.wallet-connect-container::-webkit-scrollbar {
  display: none !important;
}

.market-container {
  overflow-x: hidden;
  background: #1a1a1a;
  // height: 100vh;
  .desktop-version {
    display: none;
  }
  .coming-soon-label {
    font-weight: bold;
    font-family: "montserrat";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    color: #0a0a0a;
    background: linear-gradient(256.56deg, #f0a000 38.01%, #ffdc00 102.8%);
    border-radius: 1em;
    border: none;
    padding: 0.5em 1em;
    transform: translate(-50%, -50%);
  }
  .header-market-container {
    .market-type-container {
      font-family: "Cabin";
      font-size: 0.875rem;
      margin: 1em 0;
      overflow: scroll;
      flex-wrap: unset !important;
      .active {
        padding: 0.2em;
        background: #2a2a2a;
        width: auto;
        border-radius: 1em;
        margin: 0 0.2rem;
        label {
          background: #1a1a1a;
          color: #edebeb;
          width: 100%;
          border-radius: 1em;
          padding: 0.2em 1em;
        }
      }
      div {
        padding: 0.2em;
        background: #2a2a2a;
        width: auto;
        border-radius: 1em;
        margin: 0 0.2rem;
        label {
          color: #9e9e9e;
          width: 100%;
          border-radius: 1em;
          padding: 0.2em 1em;
        }
      }
    }
    .market-filter-container {
      font-size: 1rem;
      background: #2a2a2a;
      border-radius: 2em;
      margin: 1em 0;
      align-items: center;
      .filter-label {
        padding: 0.2em;
        &.active {
          label {
            background: #1a1a1a;
            border-radius: 2em;
            padding: 0.5rem 0.8rem;
            color: #ffdc00;
          }
        }
        label {
          font-family: "Cabin";
          padding: 0.5rem 0.8rem;
        }
      }
    }
    .market-type-container::-webkit-scrollbar {
      display: none !important;
    }
    .controls {
      margin: none;
      // width: 5em;
    }
    .segment {
      min-width: 5em;
      label {
        font-family: "Cabin";
      }
    }
  }
  .market-listing-container {
    animation: fadeInUp 500ms ease-in-out;
    .desktop-version {
      display: block !important;
    }
    padding: 0;
    padding-bottom: 5em;
    .listing-container {
      margin: 1.5em 0;
      align-items: center;
      font-family: "Cabin";
      // touch-action: none;
      // -ms-touch-action: pan-y; touch-action: pan-y;
      .title {
        svg{
          margin-right: 1em;
        }
        label {
          margin-left: 1em;
          font-size: 1rem;
          color: #edebeb;
          span {
            color: #9091a0;
            margin-left: 0.2em;
            font-size: 0.875rem;
          }
        }
        img {
          border-radius: 50%;
          margin-right: 0.5em;
        }
      }
      .rate {
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        label {
          padding: 0.2em 0;
          width: 6em;
          border-radius: 0.3rem;
          &.green {
            color: #59d08e;
            background: rgba(255, 255, 255, 0.01);
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25), inset 3px 0px 35px rgba(89, 208, 142, 0.4);
          }
          &.red {
            color: #fa6364;
            background: rgba(255, 255, 255, 0.01);
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25), inset 3px 0px 35px rgba(221, 0, 0, 0.4);
          }
        }
      }
      .price {
        line-height: 1em;
        label {
          color: #edebeb;
          font-size: 0.875rem;
          width: 100%;
        }
        span {
          font-size: 0.75rem;
          color: #9091a0;
        }
      }
    }

  }
}

.profile-container {
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: scroll;
  .profile-desktop-version {
    display: none;
  }
  background: #010001;
  height: auto;
  position: relative;
  .common-header-main {
    position: absolute;
    right: 0.75em;
  }
  .profile-picture-container {
    justify-content: center;
    margin: 2em 0;
    img {
      width: 7em;
    }
  }
  .profile-content {
    position: relative;
    top: -13em;
    padding: 0 2em;
    // padding-bottom: 5em;
    .radio-button-container {
      .radio-button {
        width: auto;
        .form-check-input:disabled {
          background-color: #efe0b4;
          opacity: 1;
        }
        .form-check-input[type="radio"]:disabled:after {
          border-radius: 50%;
          width: 0.625rem;
          height: 0.625rem;
          border-color: #000000;
          background-color: #b4b2af;
          transition: border-color;
          transform: translate(-50%, -50%);
          position: absolute;
          left: 50%;
          top: 50%;
        }
        .form-check-input[type="radio"]:before {
          background: #9e9e9e;
        }
        .form-check-input:checked {
          background-color: #f0a000;
          border-color: #000000;
          box-shadow: 0px 0px 5px rgba(255, 220, 0, 0.8);
        }
        .form-check-input[type="radio"]:checked:after {
          border-radius: 50%;
          width: 0.625rem;
          height: 0.625rem;
          border-color: #000000;
          background-color: #ffdc00;
          transition: border-color;
          transform: translate(-50%, -50%);
          position: absolute;
          left: 50%;
          top: 50%;
        }
        .date-label {
          label {
            color: #9e9e9e;
            font-size: 0.5625em;
          }
        }
      }
      .radio-button:nth-child(1) {
        position: absolute;
        top: -0.7em;
        left: 0;
      }
      .radio-button:nth-child(2) {
        position: absolute;
        top: -0.7em;
        left: 20%;
      }
      .radio-button:nth-child(3) {
        position: absolute;
        top: -0.7em;
        left: 40%;
      }
      .radio-button:nth-child(4) {
        position: absolute;
        top: -0.7em;
        left: 60%;
      }
      .radio-button:nth-child(5) {
        position: absolute;
        top: -0.7em;
        left: 80%;
      }
      .radio-button:nth-child(6) {
        position: absolute;
        bottom: -1.9em;
        right: -0.5em;
      }
      .radio-button:nth-child(7) {
        position: absolute;
        bottom: -1.9em;
        right: 15%;
      }
      .radio-button:nth-child(8) {
        position: absolute;
        bottom: -1.9em;
        right: 35%;
      }
      .radio-button:nth-child(9) {
        position: absolute;
        bottom: -1.9em;
        right: 55%;
      }
      .radio-button:nth-child(10) {
        position: absolute;
        bottom: -1.9em;
        right: 75%;
      }
    }
  }
  .profile-layout-container {
    min-height: 18em;
    // .layout-image {
    //   height: 18em;
    // }
  }
  .campaign-container {
    font-family: "Montserrat";
    animation: fadeInUp 500ms ease-in-out;
    .desktop-version {
      display: none;
    }
    color: #edebeb;
    background: #000000;
    font-weight: bold;
    font-size: 1.25rem;
    border-radius: 1em;
    padding: 1em 0em;
    padding-bottom: 3em;
    label {
      animation-delay: 1s;
      animation: fadeInUp 500ms ease-in-out;
    }
    .campaign {
      color: #f0a000;
      font-size: 1.625rem;
    }
    .alphapass {
      color: #ffdc00;
      font-size: 1.625rem;
    }
    .description-container {
      font-family: "Cabin";
      color: #9e9e9e;
      label {
        line-height: 1.5em;
        font-size: 0.875rem;
        font-weight: normal;
      }
    }
    .check-in-button {
      width: 10em;
      margin-top: 0em;
      margin-bottom: 1.5em;
      margin-left: auto;
      margin-right: auto;
      button {
        font-weight: bold;
        text-transform: capitalize;
        border-radius: 2em;
        background: linear-gradient(256.56deg, #f0a000 38.01%, #ffdc00 102.8%);
        box-shadow: 0px 0px 4px #ffdc00;
        font-size: 1.125rem;
        padding: 0.5em 1em;
        span {
          color: #1a1a1a;
        }
      }
    }
    .checkin-container {
      animation-delay: 2s;
      margin-top: 1em;
      margin-bottom: 3em;
      margin-left: 0;
      margin-right: 0;
      padding: 0 0.5em;
      .checkin-line {
        background: transparent;
        border-radius: 0 1em 1em 0;
        height: 4em;
        border: 0.1em solid #ffdc00;
        border-left: none;
        position: relative;
        .unlock {
          position: absolute;
          left: 0;
          bottom: -1em;
          background: #000001;
          color: #9e9e9e;
          padding: 0 0.5em;
          font-size: 0.75rem;
        }
        .unlocked {
          color: #ffdc00;
        }
        .start {
          position: absolute;
          left: 0;
          top: -0.5em;
          background: #000001;
          height: 2em;
          width: 1.5em;
        }
      }
    }
    .checkin-desktop-container {
      display: none;
    }
    .nft-container {
      align-items: center;
      justify-content: center;
      // margin-top: 2.5em;
      margin-bottom: 1.5em;
      img {
        width: 20em;
        height: 12em;
      }
    }
  }
  .header-container {
    text-align: end;
    label {
      color: #edebeb;
      font-size: 1.5rem;
      font-weight: bold;
    }
    span {
      color: #9e9e9e;
      font-size: 0.875rem;
    }
  }
  .referrals-container {
    .header-container {
      font-family: "Montserrat";
      span {
        font-family: "Cabin";
        // padding-left: 20%;
      }
    }
    .referrals-box {
      background: #0d0d0f;
      border-radius: 1em;
      padding: 1em;
      margin-top: 1em;
      &.desktop-version {
        display: none;
      }
      .referral-row {
        padding: 1em 0;
        font-size: 0.875rem;
        .title {
          color: #9e9e9e;
          .referral-link {
            background: linear-gradient(270deg, #edebeb 6.9%, rgba(237, 235, 235, 0) 87.53%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
        .value {
          text-align: end;
          color: #edebeb;
        }
        .button-share {
          text-align: end;
          span {
            text-transform: capitalize;
            font-size: x-small;
          }
          button {
            width: 4.5em;
            height: 4.5em;
            background: #484848;
            border-radius: 1.3em;
            padding: 0.6em;
            border: none;
            &.active {
              background: #1a1a1a;
              border: 0.1em solid #484848;
            }
            span {
              color: #9e9e9e;
            }
          }
        }
      }
      .noted {
        color: #9e9e9e;
        font-size: small;
        text-align: center;
        span {
          color: #7174eb;
        }
      }
    }
  }
  .referrals-v2-container {
    margin-top: 2em;
    .header-container {
      font-family: "Montserrat";
      span {
        font-family: "Cabin";
        // padding-left: 20%;
      }
    }
    .referrals-tab {
      width: 15em;
      margin-top: 1em;
      margin-left: auto;
      margin-right: auto;
      .controls-container {
        margin: 0;
        .controls {
          .segment {
            min-width: 101px;
            position: relative;
            text-align: center;
            z-index: 1;
          }
        }
      }
    }
    @media only screen and (min-width: 992px) {
      .referrals-box {
        padding: 1.5em 4em !important;
        .referral-row {
          text-align: center;
          margin-top: auto;
          margin-bottom: auto;
          .title {
            text-align: start;
          }
        }
      }
    }
    .referrals-box {
      background: #0d0d0f;
      border-radius: 1.5em;
      padding: 1em;
      margin-top: 1em;
      &.desktop-version {
        display: none;
      }
      .deco-referral {
        border-bottom: 1px solid #9e9e9e1f;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }
      .referral-row {
        padding: 1em 0;
        font-size: 0.875rem;
        .title {
          font-family: "montserrat";
          font-size: 14px;
          color: #9e9e9e;
          .referral-link {
            background: linear-gradient(270deg, rgba(237, 235, 235, 0) 6.9%, #edebeb 61.28%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
        .title-bold {
          font-family: "montserrat";
          font-size: 14px;
          color: #edebeb;
        }
        .sub-title {
          font-family: "montserrat";
          color: #9e9e9e;
          font-size: 12px;
          font-weight: 400;
        }
        .value {
          text-align: end;
          color: #edebeb;
        }
        .button-share {
          text-align: end;
          span {
            text-transform: capitalize;
            font-size: x-small;
          }
          button {
            width: 4.5em;
            height: 4.5em;
            background: #484848;
            border-radius: 1.3em;
            padding: 0.6em;
            border: none;
            &.active {
              background: #1a1a1a;
              border: 0.1em solid #484848;
            }
            span {
              color: #9e9e9e;
            }
          }
        }
      }
      .title-bold {
        font-family: "cabin";
        font-size: 22px;
        color: #edebeb;
      }
      .title-sub {
        font-family: "montserrat";
        font-size: 14px;
      }
      .value {
        font-family: "montserrat";
        font-size: 14px;
        color: #edebeb;
      }
      .referral-code {
        label {
          background: linear-gradient(314.37deg, rgba(255, 255, 255, 0) 8.93%, #9e9e9e 135.23%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
      .input-referral-container {
        font-size: 14px;
        .input-referral {
          border-bottom: 0.1em solid #9e9e9e47;
          margin: 1em 0em 0em 0em;
          padding: 0.5em 0;
          &.enter-code {
            margin: 1em 0em 0em 0em;
          }
        }
        input {
          text-align: center;
          width: 11em;
          background: transparent;
          border: none;
          color: #edebeb;

          font-family: "montserrat";
        }
        input:read-only {
          color: #edebeb;
        }
        input::placeholder {
          font-family: "montserrat";
          color: rgba(158, 158, 158, 0.5);
        }
        input:focus {
          font-family: "montserrat";
          outline: none;
          color: #c7c5c57b;
        }
        svg {
          width: auto;
          padding: 0;
        }
      }
      .referrals-btn {
        position: relative;
        margin: 0;
        padding: 1.5em 0 1em 0;
        .connect-btn {
          border-radius: 0.8em;
          background: #edebeb;
          font-family: "montserrat";
          color: #2a2a2a;
          text-transform: capitalize;
          padding: 0.7em 1em 0.7em 1em;
          font-size: 14px;
        }
        .submit-btn {
          border-radius: 0.8em;
          border: 0.1em solid #edebeb;
          font-family: "montserrat";
          color: #edebeb;
          background: transparent;
          text-transform: none;
          padding: 0.7em 1em 0.7em 1em;
          font-size: 14px;
        }
        .submit-btn:hover {
          border-radius: 0.8em;
          border: 0.1em solid #edebeb;
          font-family: "montserrat";
          color: #edebeb;
          background: transparent;
          text-transform: none;
          padding: 0.7em 1em 0.7em 1em;
          font-size: 14px;
        }
        .creating-btn {
          border-radius: 0.8em;
          border: 0.1em solid none;
          font-family: "montserrat";
          color: #edebeb;
          background: transparent;
          text-transform: none;
          padding: 0.7em 1em 0.7em 1em;
          font-size: 14px;
          background: linear-gradient(256.56deg, #f0a000 38.01%, #ffdc00 102.8%);
          box-shadow: inset -1px 0px 3px rgba(216, 211, 211, 0.25);
        }
        .history-btn {
          border-radius: 0.8em;
          border: 0.1em solid none;
          font-family: "montserrat";
          color: #edebeb;
          text-transform: none;
          padding: 0.7em 1em 0.7em 1em;
          font-size: 14px;
          // background: transparent;
          // background: linear-gradient(256.56deg, #f0a000 38.01%, #ffdc00 102.8%);
          // box-shadow: inset -1px 0px 3px rgba(216, 211, 211, 0.25);
          background: #0D0D0F;
          border: 1px solid rgba(171, 174, 205, 0.3);
          box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
        }
        .more-history-btn {
          color: #7174eb;
          background-color: transparent;
          border: none;
        }
        .setting-container {
          background: #2a2a2a;
          position: absolute;
          bottom: -3em;
          width: 10em;
          border-radius: 1em;
          height: 2.5em;
          right: 0%;
          transform: translate(-10%);
          align-items: start;
          display: grid;
          padding: 0.5em;
          z-index: 9999 !important;
          &.recover-key {
            font-family: montserrat;
            top: 5.5em;
            right: -0.5em;
            height: 12em;
            width: 15em;
            padding: 1em;
            align-items: center;
            z-index: 9999 !important;
            label {
              color: #cdc7c7;
              text-align: start;
              font-size: medium;
              span {
                font-size: small;
              }
            }
            .recover-btn {
              // margin-top: 1em;
              display: flex;
              justify-content: center;
              padding: 0.3rem 1em;
              text-align: center;
              display: flex;
              align-items: center;
              background: #ffdc00;
              font-size: $font-size-14;
              border-radius: 0.5em;
              color: #495057 !important;
              border: none;
            }
            .disconnect-btn {
              margin-top: 1em;
            }
          }
          .disconnect-btn {
            border: none;
            text-transform: capitalize;
            background-color: transparent;
            box-shadow: none;
            color: #6f6e84;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: small;
            svg {
              margin-right: 0.5em;
            }
          }
          .disconnect-btn:hover {
            color: #fa6364;
            svg {
              color: #fa6364 !important;
            }
          }
        }
        @media only screen and (min-width: 992px) {
          .more-history-btn {
            width: 12em;
          }
        }
      }
      .noted {
        color: #9e9e9e;
        font-size: small;
        text-align: center;
        span {
          color: #7174eb;
        }
      }
      .rebate-title-bold {
        font-family: "montserrat";
        color: #edebeb;
        font-size: 16px;
      }
      .rebate-header {
        padding-top: 2em;
        .rebate-title {
          font-family: "montserrat";
          color: #9e9e9e;
          font-size: 14px;
        }
      }
    }
  }
  .leaderboard-container {
    margin-top: 2em;
    .tab-leaderboard {
      font-family: "Cabin";
      margin: 1em 0;
      .button-tab {
        &.active {
          background: #1a1a1a;
          border: 0.1em solid #ffdc00;
          color: #edebeb;
        }
        border: 0.1em solid #1a1a1a;
        background: #2a2a2a;
        border-radius: 2em;
        text-align: center;
        padding: 0.2em;
        color: #9e9e9e;
      }
    }
    .trading-setting{
      // height: 0;
      overflow: hidden;
      padding: 0.2em;
      transition: height 0.5s;
      font-size: 0.75rem;
      display: grid;
      justify-content: end;
      align-items: center;
      text-align: end;
      padding: 0em 2em;
      overflow: scroll;
      .row{
        margin: 0;
      }
      ._label{
        &.--dropdown-label{
          margin-bottom: 1em;
        }
        &.--dropdown-label:hover{
          color: #edebeb;
        }
      }
      &.show{
        // height: 3em;
        transition: height 0.5s;
        padding: 0em 2em;
      }
    }
    .trading-setting::-webkit-scrollbar {
      display: none !important;
    }
    .leaderboard-box {
      font-family: "montserrat";
      background: #0d0d0f;
      border-radius: 1em;
      color: #9e9e9e;
      padding: 0;
      .padding {
        padding: 0.5em 1em;
      }
      .filter-row {
        padding-top: 1em;
        label {
          font-size: x-small;
          &.active {
            font-size: 1rem;
            color: #edebeb;
          }
          svg {
            margin-right: 0.5em;
          }
          width: auto;
        }
      }
      .line {
        border: 1px solid #9e9e9e;
        opacity: 0.1;
        z-index: 0;
        position: fixed; //z-index
      }
      .leaderboard-row {
        font-family: "montserrat";
        padding: 0.5em 1em;
        align-items: center;
        .name {
          white-space: nowrap;
          overflow: hidden;
          background: linear-gradient(270deg, rgba(237, 235, 235, 0) 6.9%, #edebeb 61.28%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          img {
            margin-right: 0.5em;
          }
          span {
            margin-right: 1em;
            margin-left: 0em;
          }
        }
      }
    }
  }
}
.rebate-history-container {
  position: absolute;
  animation: fadeInUp 500ms ease-in-out;
  border-radius: 2em 2em 0em 0em;
  width: 100%;
  bottom: 0 !important;
  margin: 0 !important;
  // top: 19%;
  // transform: translate(-50%, 10%) !important;
  // inset: 50% auto 50% !important;
  .modal-body {
    border-radius: 2em 2em 0 0;
    color: #9e9e9e;
    min-height: 25em;
    .deco-referral {
      margin: 0.5em;
      border-bottom: 1px solid #9e9e9e1f;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
    .referral-box {
      .referral-row {
        margin: 0.5em 0;
      }
    }
    .title-bold {
      font-family: "cabin";
      font-size: 22px;
      color: #edebeb;
    }
    .title-sub {
      font-family: "montserrat";
      font-size: 14px;
    }
    .value {
      font-family: "montserrat";
      font-size: 14px;
      color: #edebeb;
    }
    .referral-code {
      label {
        background: linear-gradient(314.37deg, rgba(255, 255, 255, 0) 8.93%, #9e9e9e 135.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}
.referrals-btn {
  margin: 0;
  padding: 1.5em 0 1em 0;
  .connect-btn {
    cursor: pointer;
    border-radius: 0.8em;
    background: #edebeb;
    font-family: "montserrat";
    color: #2a2a2a;
    text-transform: capitalize;
    padding: 0.7em 1em 0.7em 1em;
    font-size: 14px;
  }
  .submit-btn {
    cursor: pointer;
    border-radius: 0.8em;
    border: 0.1em solid #edebeb;
    font-family: "montserrat";
    color: #edebeb;
    background: transparent;
    text-transform: none;
    padding: 0.7em 1em 0.7em 1em;
    font-size: 14px;
  }
  .submit-btn:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  .submit-btn:hover {
    cursor: pointer;
    border-radius: 0.8em;
    border: 0.1em solid #edebeb;
    font-family: "montserrat";
    color: #edebeb;
    background: transparent;
    text-transform: none;
    padding: 0.7em 1em 0.7em 1em;
    font-size: 14px;
  }
  .creating-btn {
    cursor: pointer;
    border-radius: 0.8em;
    border: 0.1em solid none;
    font-family: "montserrat";
    color: #edebeb;
    background: transparent;
    text-transform: none;
    padding: 0.7em 1em 0.7em 1em;
    font-size: 14px;
    background: linear-gradient(256.56deg, #f0a000 38.01%, #ffdc00 102.8%);
    box-shadow: inset -1px 0px 3px rgba(216, 211, 211, 0.25);
  }
  .creating-btn:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  .history-btn {
    cursor: pointer;
    border-radius: 0.8em;
    border: 0.1em solid none;
    font-family: "montserrat";
    color: #edebeb;
    background: transparent;
    text-transform: none;
    padding: 0.7em 1em 0.7em 1em;
    font-size: 14px;
    background: linear-gradient(256.56deg, #f0a000 38.01%, #ffdc00 102.8%);
    box-shadow: inset -1px 0px 3px rgba(216, 211, 211, 0.25);
  }
  .more-history-btn {
    cursor: pointer;
    color: #7174eb;
    background-color: transparent;
    border: none;
  }
}
// @media only screen and (min-width: 576px) {
.ReactModalPortal {
  .ReactModal__Overlay {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 9998;
    .rebate-history-container {
      position: relative;
      animation: fadeInUp 500ms ease-in-out;
      // width: 25em !important;
      // margin-left: auto !important;
      // margin-right: auto !important;
      // transform: translate(-50%, -50%) !important;
      // inset: 50% auto auto 50% !important;
      overflow: hidden;
      height: auto;
      .modal-body {
        height: 30em;
        margin: 1em;
        overflow: scroll;
        border-radius: 2em !important;
        background: #1a1a1a;
      }
      .modal-body::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}
// }
.wallet-connect-container::-webkit-scrollbar {
  display: none !important;
}
.p-inline-message{
  &.error-msg{
    border: solid #ff5353 !important;
    border-width: 0 0 0 6px !important;
    color: #EDEBEB!important;
    line-height: 1.2em;
    font-size: 0.875rem;
    background:#56161652!important;
  }
  &.warm-msg{
    border-width: 0px 0px 0px 6px !important;
    border-style: solid;
    border-color: rgb(255, 182, 72)!important;
    border-image: initial;
    color: rgb(237, 235, 235)!important;
    line-height: 1.2em;
    font-size: 0.875rem;
    background: rgba(255, 182, 72, 0.1)!important;
  }
}
.p-dropdown-panel{
  margin-top: 0.2em;
  border-radius: 0.5em !important;
  overflow: hidden !important;
  background: #2A2A2A !important;
  .p-dropdown-header{
    color: #EDEBEB !important;
    background: #0D0D0F !important;
    border-bottom: 0 !important;
    .p-dropdown-filter-container{
      input{
        padding: 0.5em;
        border: 1px solid #424242;
        background-color: #0D0D0F;
        box-shadow: none;
        color: #EDEBEB !important;
      }
      i{
        color: #EDEBEB !important;
      }
      i::before{
        color: rgb(158, 158, 158) !important;
      }
    }
  }
  .p-dropdown-items-wrapper{
    background-color: #0D0D0F !important;
    .p-dropdown-items{
      .p-dropdown-item{
        text-align: center;
        font-family: 'cabin';
        font-size: 0.875rem;
        &.p-highlight{
          color: #FFDC00 !important;
          background: transparent !important;
        }
        &.p-highlight:hover{
          color: #FFDC00 !important;
          background: #2A2A2A  !important;
        }
      }
      li{
        color: #EDEBEB ;
      }
      li:hover{
        color: #EDEBEB !important;
        background: #2A2A2A !important;
      }
    }
  }
}


.action-panel-container{
  font-family: 'cabin';
  border-radius: 1em;
  background: #242628;
  min-height: 20em;
  padding: 1em !important;
  margin-top: 1em;
  margin-bottom: 5em;
  position: relative;
  .panel-tab{
    ._label{
      color: #EDEBEB;
      // font-size: 0.875rem;
      &.--icon{
        font-size: 1rem;
        margin-top: auto;
        margin-bottom: auto;
        svg{
          margin-right: 0.5em;
          margin-bottom: 0.2em;
        }
      }
      &.--position,
      &.--order,
      &.--fill{
        text-align: center;
        border-bottom: 0.2em solid #FFDC00;
      }
    }
  }
  .line-container{
    &.--dark{
      margin: .5em 0em 1em 0em;
      background-color: rgba(158, 158, 158, 0.5);
      width: 100%;
      height: 0.1em;
    }
    &.--ligth{
      margin: 1.5em 0em;
      background-color: #9e9e9e2f;
      width: 100%;
      height: 0.1em;
    }
  }
  .position-card{
    padding: 0;
    .fix-colums{
      max-width: 6em;
      &.--position{
        max-width: 5em;
      }
      &.--button{
        max-width: 1em;
        margin-left: 1.5em;
      }
    }
    .responsive-colums{
      min-width: 6em;
    }
    .position-row{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .--share-button{
        position: absolute;
        right: -2em;
        top: -0.5em;
        width: auto;
      }
    }
    .position-container:hover{
      svg{
        color: rgb(240, 160, 0)
      }
    }
    .position-row:hover{
      ._label{
        &.--normal-label-title{
          padding: 0.5em 0em 0em 0em;
          font-weight: normal;
          display: flex;
          align-items: center;
          width: auto;
          color: #eeeeeeb6;
          font-size: 0.875rem;
        }
        &.--normal-label{
          display: flex;
          align-items: center;
          justify-content: center;
          color: #eeeeeeb6;
          font-size: 0.813rem;
        }
        &.--long-text{
          width: 100%;
          font-size: 1rem;
          color: #59D08E;
        }
        &.--short-text{
          width: 100%;
          font-size: 1rem;
          color: #FA6364;
        }
      }
    }
    ._label{
      &.--long{
        text-transform: uppercase;
        font-weight: bold;
        width: auto;
        padding: 0.5em 1em;
        color: #EDEBEB;
        font-size: 0.75rem;
        background: #1F4F34;
        border-radius: 0.5em;
        display: flex;
        align-items: center;
        span{
          text-transform: capitalize;
        }
      }
      &.--short{
        text-transform: uppercase;
        font-weight: bold;
        width: auto;
        padding: 0.5em 1em;
        color: #EDEBEB;
        font-size: 0.75rem;
        background: #602121;
        border-radius: 0.5em;
        display: flex;
        align-items: center;
        span{
          text-transform: capitalize;
        }
      }
      &.--normal-label-title{
        padding: 0.5em 0em 0em 0em;
        font-weight: normal;
        display: flex;
        align-items: center;
        width: auto;
        color: #EDEBEB;
        font-size: 0.875rem;
      }
      &.capital-text{
        text-transform: capitalize;
      }
      &.--normal-label{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #EDEBEB;
        font-size: 0.813rem;
        &.--filled{
          color: rgb(89, 208, 142);
        }
        &.--cancel{
          color: rgb(250, 99, 100);
        }
      }
      &.--dot-line-title{
        width: auto;
        color: #B1B1C9;
        font-size: 0.75rem;
        border-bottom: 0.1em dotted #B1B1C9;
      }
      &.--long-text{
        width: 100%;
        font-size: 0.813rem;
        color: #59D08E;
        text-align: center;
      }
      &.--short-text{
        width: 100%;
        font-size: 0.813rem;
        color: #FA6364;
        text-align: center;
      }
      &.--title{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #B1B1C9;
        font-size: 0.75rem;
        white-space: nowrap;
      }
      &.--profit{
        width: 100%;
        font-size: 1.25rem;
        color: #59D08E;
      }
      &.--loss{
        width: 100%;
        font-size: 1.25rem;
        color: #FA6364;
      }
      &.--order-date{
        font-size: 0.625rem;
        color: #9091A0;
      }
    }
    ._button{
      border: 1px solid #ABAECD;
      background-color: transparent;
      font-size: 0.875rem;
      border-radius: 0.5em;
      color: #9E9E9E;
      white-space: nowrap;
      padding: 0.3em 1em;
      &.--cancle{
        border: none;
        display: flex;
        width: auto;
        background: #2A2A2A;
        padding: 0.3em 2em;
        border-radius: 2em;
      }
    }
    ._button:hover{
      color: #FFDC00;
    }
    .position-row{
      margin-top: 0.5em !important;
      margin-bottom: 0.5em !important;
      margin: 0;
    }
  }
  .history-card{
    background: transparent;
    border: 1px solid rgba(158, 158, 158, 0.1);
    border-radius: 0.5em;
    padding: 0.5em;
    white-space: nowrap;
    margin-top: 1em;
    ._label{
      display: flex;
      align-items: center;
      &.--long,&.--sell{
        text-transform: capitalize;
        font-weight: bold;
        font-size: 0.75rem;
        color: #FA6364;
      }
      &.--short,&.--buy{
        text-transform: capitalize;
        font-weight: bold;
        font-size: 0.75rem;
        color: #59D08E;
      }
      &.--normal-grey-text{
        text-transform: capitalize;
        font-size: 0.75rem;
        color: #9E9E9E;
      }
      &.--bold-grey-text{
        font-weight: bold;
        font-size: 0.75rem;
        color: #9E9E9E;
      }
      &.--normal-text{
        text-transform: capitalize;
        font-size: 0.625rem;
        color: #9091A0;
      }
    }
  }
  .connect-btn,
  .boading-btn{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    button{
      white-space: nowrap;
      max-width: 11em;
      border: none;
      font-size: 1rem;
      border-radius: 0.5em;
      text-transform: capitalize;
      font-weight: normal;
      padding: 0.5em;
      text-align: center;
      display: flex;
      align-items: center;
      background: #FFDC00;
      font-size: 0.875rem;
      color: #000000;
      font-family: "montserrat";
    }
  }
}
.trade-favorite-list{
  // display: contents;
  align-items: center;
  margin: 0;
  padding: 0.3em;
  .trade-favorite-content{
    display: flex;
    overflow: scroll;
  }
  .trade-favorite-content::-webkit-scrollbar {
  display: none !important;
  }  
  svg{
    // padding: 0.5em;
  }
  .favourite-item{
    padding-left: 1em;
    width: auto;
    white-space: nowrap;
    img{
      padding-right: 0.5em;
    }
  }
  // .favourite-item:first-child{
  //   padding-left: 0em;
  //   img{
  //     padding-right: 0.5em;
  //   }
  // }
  ._label{
    text-align: center;
    &.--symbol{
      font-size: 0.875rem;
      color: #EDEBEB;
    }
    &.--currency{
      font-size: 0.75rem;
      color: #9091A0;
    }
    &.--price-rate{
      padding: 0em 0.8em;
      font-size: 0.875rem;
      color: #59D08E;
    }
  }
}


.trade-view-header{
  height: 3em;
  font-family: 'cabin';
  display: flex;
  align-items: center;
  justify-content: start;

  .p-dropdown{
    background: transparent !important;
    border: none!important;
    border-radius: 0.5em !important;
    width: 10em;
    outline: none;
    &.p-focus{
      box-shadow: none!important;
    }
    .p-dropdown-label{
      font-family: 'cabin';
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-left: 0;
      padding-right: 0em;
      font-size: 1.5rem;
      color: #EDEBEB;
      text-align: start;
    }
    .p-dropdown-trigger{
      width: 2.5rem;
      justify-content: start;
      .p-dropdown-trigger-icon{
        font-size: 0.8rem;
        padding: .5em;
      }
    }
  }
  .trade-view-details{
    &.--symbol{
      width: 10em;
      padding: 0.5em
    }
    &.--price-rate{
      width: 7em;
      padding: 0.5em 1.5em
    }
  }
  .trade-view-col{
    width: 10em;
    padding: 0.5em 1.5em;
    border-left: 1px solid #414141;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  ._label{
    width: 100%;
    &.--long{
      color: #59D08E;
    }
    &.--short{
      color: #FA6364;
    }
    &.--trade-chain{
      color: #EDEBEB;
      font-size: 1.25rem;
      padding: 0.5em;
      text-align: start;
      padding: 0;
      align-items: center;
      display: flex;
      svg{
        margin-left: 0.5em;
      }
    }
    @media only screen and (max-width: 992px) {
      &.--trade-chain{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2A2A2A;
        border-radius: 0.5em;
        color: #EDEBEB;
        font-size: 1.25rem;
        padding: 0.4em 0.8em;
        svg{
          margin-left: 0.5em;
        }
      }
    }
    &.--change-rate{
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: end;
    }
    &.--trade-price{
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 1.25rem;
    }
    &.--trade-rate{
      font-size: 0.875rem;
      color: #EDEBEB;
      display: flex;
      align-items: center;
      justify-content: start;
    }
    &.--high-price{
      color: #6F7283;
      font-size: 0.625rem;
      span{
        color: #EDEBEB;
        font-size: 0.75rem;
        padding-left: 0.5em;
      }
    }
    &.--low-price{
      color: #6F7283;
      font-size: 0.625rem;
      span{
        color: #EDEBEB;
        font-size: 0.75rem;
        padding-left: 0.5em;
      }
    }
    &.--trade-label{
      white-space: nowrap;
      line-height: 1em;
      text-align: start;
      color: #6F7283;
      font-size: 0.75rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .trade-view-header{
    height: 2.5em;
    margin: 0.5em 0;
    overflow: hidden;
  }
}

.trade-box-container{
  font-family: 'cabin';
  width: 100%;
  .leverage-container{

  }
  .slide-container{
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5em;
    // margin-bottom: 2em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    .rc-slider{
      padding: 0;
      .rc-slider-rail,.rc-slider-track,.rc-slider-step{
        background: rgba(255, 220, 0, 0.3);
        height: 0.05em;
        .rc-slider-dot{
          &.rc-slider-dot-active{
            opacity: 1;
            width: 0.3em;
            height: 1em;
            background: #FFDC00;
            border-radius: 1px;
            margin-top: -0.75em;
          }
          bottom: -8px;
          opacity: 1;
          width: 0.3em;
          height: 1em;
          background: #2A2A2A;
          border: transparent;
          border-radius: 1em;
        }
      }
      .rc-slider-track{
        height: 0.15em;
        background: rgb(255, 221, 0);
      }
      .rc-slider-handle{
        &.rc-slider-handle-dragging{
          box-shadow: 0 0 0 1px #F0A000;
        }
        opacity: 1;
        width: 0.7em;
        height: 1.5em;
        background: #FFDC00;
        border: 0.2em solid #F0A000;
        border-radius: 0.2em;
        margin-top: -0.8em;
      }
      .rc-slider-handle:focus-visible{
        box-shadow: 0 0 0 1px #F0A000;
      }
      .rc-slider-mark{
        top: 22px;
      }
    }
    .horizontal-slider{
      background: rgba(255, 220, 0, 0.3);
      height: 0.05em;
      .example-track{
        background: rgb(255, 221, 0);
        height: 0.15em;
      }
      .example-mark{
        bottom: -8px;
        opacity: 1;
        width: 0.3em;
        height: 1em;
        background: #2A2A2A;
        border: transparent;
        border-radius: 1em;
      }
    }
    .MuiSlider-root{
      color: rgba(255, 220, 0, 0.3);
      .MuiSlider-thumb{
        opacity: 1;
        width: 0.9em;
        height: 1.5em;
        background: #FFDC00;
        border: 0.2em solid #F0A000;
        border-radius: 0.2em;
        box-shadow: none;
      }
      .Mui-active{
        box-shadow: 0 0 0 5px #f0a00066;
      }
      .MuiSlider-track{
        color: rgb(255, 221, 0);
      }
      .MuiSlider-markLabel{
        color: #999;
        font-size: 12px;
      }
      .MuiSlider-mark{
        bottom: -8px;
        opacity: 1;
        width: 0.5em;
        height: 1.1em;
        background: #2A2A2A;
        border: transparent;
        border-radius: 0.1em;
      }
      .MuiSlider-markActive{
        opacity: 1;
        width: 0.5em;
        height: 1.1em;
        background: #FFDC00;
        border-radius: 0.1em;
      }
    }
  }
  .columns{
    padding-bottom: 0.5em;
  }
  ._button{
    &.--confirm{
      margin-top: 1em;
      border: none;
      width: 100%;
      border-radius: 0.5em;
      padding: 0.5em;
      font-size: 1.125rem;
      color: #1A1A1A;
      cursor: pointer;
      &.--long{
        background-color: rgb(89, 208, 142);
      }
      &.--short{
        background-color: rgb(250, 99, 100);
      }
    }
    &.--confirm:disabled{
      margin-top: 1em;
      border: none;
      width: 100%;
      border-radius: 0.5em;
      padding: 0.5em;
      font-size: 1.125rem;
      color: #6F7283;
      background-color: #44414145;
      cursor: not-allowed;
    }
  }
  .order-avanced-setting{
    height: 0;
    overflow: hidden;
    padding: 0.2em;
    transition: height 1s;
    &.show{
      height: 5em;
      transition: height 1s;
      padding: 0.2em;
    }
  }  
  .line-container{
    &.--input-line{
      margin: 0.2em 0em;
      background-color: #393939;
      width: 100%;
      height: 0.15em;
    }
  }
  ._label{
    color: rgb(177, 177, 201);
    font-size: 0.75rem;
    &.--bold{
      // font-weight: bold;
      font-size: 1rem;
      color: #FEFEFE;
      display: flex;
      align-items: center;
    }
    &.--normal-text{
      line-height: 1em;
      font-size: 0.875rem;
      color: #FEFEFE;
      display: flex;
      align-items: center;
    }
    &.--small-title{
      font-size: 0.625rem;
      color: #6F7283;
      text-decoration: underline;
    }
    &.--count-down{
      font-size: 0.625rem;
      color: #EDEBEB;
    }
    &.--title{
      font-size: 0.875rem;
      color: #9E9E9E;
    }
    &.--total-title{
      width: 100%;
      font-size: 1rem;
      color: #6F7283;
    }    
    &.--available-title{
      padding-left: 0;
      padding-right: 0.5em;
      width: auto;
      font-size: 0.875rem;
      color: #6F7283;
    }
    &.--amount{
      width: 100%;
      font-size: 0.875rem;
      color: #EDEBEB;
    }
    &.--available-amount{
      padding: 0;
      width: auto;
      font-size: 0.875rem;
      color: #EDEBEB;
    }
    &.--amount-type{
      font-size: 0.75rem;
    }
  }
  ._input{
    background: #2A2A2A;
    padding: 0.2em 0.5em;
    border-radius: 0.5em;
    &.--leverage{
      padding: 0.5em;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      color: #FEFEFE;
      font-size: 1rem;
      text-align: center;
    }
    &.--trigger-price{
      padding: 0.63em 0.5em;
      input{
        outline: none;
        font-size: 1rem;
        width: 100%;
        text-align: center;
        background-color: transparent;
        border: none;
        color: #EDEBEB;
      }
      input[type="number"]::-webkit-outer-spin-button, 
      input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
      }
    }
    &.--trigger-price-disable{
      background-color: transparent;
      padding: 0.63em 0.5em;
      input{
        outline: none;
        font-size: 1rem;
        width: 100%;
        text-align: center;
        background-color: transparent;
        border: none;
        color: #6F7283;
      }
      input[type="number"]::-webkit-outer-spin-button, 
      input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
      }
    }
    &.--collateral{
      outline: none;
      font-size: 1rem;
      width: 100%;
      text-align: start;
      background-color: transparent;
      border: none;
      color: #EDEBEB;
    }
    input[type="number"]::-webkit-outer-spin-button, 
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
  }
  input[type="number"]::-webkit-outer-spin-button, 
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  ._button{
    &.--max{
      padding: 2px 12px;
      background: rgba(72, 72, 72, 0.2);
      border: 1px solid #ABAECD;
      border-radius: 10px;
      color: #FEFEFE;
      font-size: 1rem;
    }
  }
  .trade-columns{
    padding: 0.2em;
    &.spacing{
      padding-left: 0.5em;
    }
  }
  .amount-columns{
    border-radius: 0.5em;
    background: #2A2A2A;
    padding: 0.5em;
    margin: 0.5em 0em 0.8em 0em;
  }
  .p-dropdown{
    background: #2A2A2A;
    border: none;
    width: 100%;
    outline: none;
    border-radius: 0.5em;
    &.p-focus{
      box-shadow: none!important;
    }
    .p-dropdown-label{
      font-family: 'cabin';
      padding: 0.67em 0.55em;
      font-size: 1rem;
      color: #EDEBEB;
      text-align: center;
    }
    @media only screen and (max-width: 992px) {
      .p-dropdown-label{
        font-family: 'cabin';
        padding: 0.7em 0.55em;
        font-size: 1rem;
        color: #EDEBEB;
        text-align: center;
      }
    }
    .p-dropdown-trigger{
      width: 2rem;
      padding-right: 0.5em;
      .p-dropdown-trigger-icon{
        font-size: 0.8rem;
      }
    }
  }
  .leverage-label{
    label{
      background: #3E3F40;
      color: #EDEBEB;
      font-size: 0.75rem;
      padding: 0.2em 0.8em;
      margin-right: 0.5em;
      border-radius: 0.5em;
    }
  }
  .detail-label{
    display: grid;
    justify-content: end;
    align-items: center;
  }
  .controls-container{
    .controls{
      background-color: #2A2A2A;
      color: #6F7283;
      border-radius: 0.8em;
      max-width: none;
      .segment{
        width: 100%;
        min-width: 0 !important;
      }
      label{
        // min-width: 12em;
      }
      &.long{
        .active{
          label{
            color: #1A1A1A;
          }
        }
      }
      &.long::before{
          background: #59D08E;
          border-radius: 0.5em;
      }
      &.short{
        .active{
          label{
            color: #1A1A1A;
          }
        }
      }
      &.short::before{
          background: #FA6364;
          border-radius: 0.5em;
      }
    }
  }
}

.share-modal-box{
  outline: none !important;
  width: 100%;
  max-width: 25em;
  margin: 1em;
  
  ._label{
    font-family: 'montserrat';
    color: #aa8508;
    &.--rate{
      font-size: 1.5em;
      font-weight: bold;
      font-family: 'montserrat';
      &.--profit{
        color: #00c65a;
      }
      &.--loss{
        color: #FA6364;
      }
    }
    &.--symbol{
      font-size: small;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        margin-left: 0.7em;
        font-size: 0.4rem;
        padding: 0.3em 0.5em;
        border-radius: 0.5em;
        text-transform: uppercase;
        &.--long{
          background-color: #68c891;
          color: #1e9c57;
        }
        &.--short{
          color: #FA6364;
        }
      }
    }
    &.--price-label{
      font-size: 0.28em;
      width: 100%;
      padding: 0;
    }
    &.--price{
      font-size: 0.4em;
      font-weight: bold;
      width: 100%;
      padding: 0;
    }
    &.--qrcode-label{
      color: #aa8508;
      font-weight: 700;
      font-size: 0.3em;
      margin-top: 1em;
    }
  }
  ._button{
    &.--share{
      margin-top: 5em;
      width: 15em;
      border: 1px solid #ABAECD;
      box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
      border-radius: 8px;
      background-color: transparent !important;
    }
  }
  .border-container{
    border-radius: 1em;
    overflow: hidden;
  }
  .card-container{
    .card{
      background-color: #FFFFFF;
      border-radius: 0em;
      height: 16em;
      padding: 1em;
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      .content-background{
        width: 20em;
        right: 1.5em;
        position: absolute;
        top: 0;
        gap: 1rem;
        @media only screen and (max-width:435px) {
          width: 89%;
          right: 2%;
          position: absolute;
          top: 0;
          gap: 0rem;
        }
      }
      .label-column{
        display: flex;
        justify-content: center;
        gap: 0em;
        line-height: 0.5em;
        div:first-child{
          width: auto;
          padding: 0;
          .--price{
            border: none;
          }
        }
        div{
          width: auto;
          padding: 0;
          .--price{
            border-left: 0.1em solid #aa8508;
          }
        }
      }
      img{
        position: absolute;
        width: 10em;
        right: 1em;
        bottom: 1em;
      }
    }
  }
  .bottom-container{
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    margin: 0;
    height: 0;
    transform: translateX(-50%);
    width: 26em;
    transition: height 0.5s;
    @media only screen and (min-width: 992px) {
      &.--show{
        height: 21em !important;
        transition: height 0.5s;
      }
      .card{
        max-width: 25em;
        height: 18em !important;
        padding: 1em;
      }
    }
    .card{
      height: 35em;
      padding: 1.5em;
    }
    &.--show{
      height: 21em;
      transition: height 0.5s;
    }
    ._action-card{
      cursor: pointer;
      font-family: 'cabin';
      &.--social{
        display: grid;
        padding: 0.5em;
        width: 7em;
        border: 0.1em solid #2f2c2c2e;
        border-radius: 0.5em;
        text-align: center;
        place-items: center;
        label{
          font-size: 0.875rem;
          width: 100%;
        }
      }
      &.--save{
        padding: 1em;
        border-radius: 0.5em;
        background-color: #2f2c2c2e;
        text-align: center;
        font-family: 'cabin';
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2em;
        svg{
          margin-right: 0.2em;
        }
      }
    }
    ._action-card:hover{
      box-shadow: 0.2em 0.2em 0.1em 0em slategrey;
    }
    ._action-card:active {
      box-shadow: 0em 0em slategrey;
      transform: translate(0.1em);
    }
  }
  &.--position-card{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    margin: 0;
    max-width: 25em;
    .card{
      box-shadow: none !important;
    }
  }
  .qr-gen{
    border: 0.2em solid white;
  }
}

.trading-view-container{
  border-radius: 1em;
  overflow: hidden;
  iframe{
    // border: 1px solid #242629;
    // border-radius: 1em;
  }
}


/*!****************
   Pages Component END
******************/

.swiper {
  .swiper-wrapper {
    .swiper-slide {
      opacity: 0 !important;
    }
    .swiper-slide-visible {
      opacity: 1 !important;
    }
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10em;
  img {
    width: 3em;
  }
}

/*!****************
    Font Family
******************/
@font-face {
  font-family: "montserrat";
  src: url(./assets/fonts/Montserrat-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: "cabin";
  src: url(./assets/fonts/Cabin-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/password.ttf);
  font-display: swap;
}

/*!****************
  Font Family END
******************/

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes moveToRight {
  0% {
    width: 20%;
  }
  100% {
    width: 100%;
  }
}

@keyframes moveToTop {
  0% {
    height: 0em;
    opacity: 0.5;
  }
  20% {
    height: 0em;
    opacity: 0.5;
  }
  60% {
    height: 0em;
    opacity: 0.5;
  }
  100% {
    height: 18em;
    opacity: 1;
  }
}

@keyframes moveToLeft {
  0% {
    width: 100%;
  }
  100% {
    width: 20%;
  }
}

/*!****************
    BottomSheet
******************/

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-footer] {
  padding: 0 !important;
}

/*!****************
  BottomSheet END
******************/

//
.backdrop-container{
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  transform: translate(0,0);
  backdrop-filter: blur(3px);
}

.szh-menu-button {
  color: #9E9E9E;
}

.szh-menu-container{
  .szh-menu{
    border-radius: 0.5em;
    background: #2A2A2A;
    li, .szh-menu__submenu{
      color: #9E9E9E ;
      svg{
        margin-right: 0.5em;
      }
      .szh-menu__item{
        button{
          color: #9E9E9E;
        }
      }
      .szh-menu__item--hover{
        background: transparent;
        button{
          color: #EBEBEB;          
          svg{
            color: #EBEBEB;
          }
        }
      }
    }
    li:hover, .szh-menu__submenu:hover{
      color: #EBEBEB;
      background: transparent;
      svg{
        color: #EBEBEB !important;
      }
    }
  }
}

@keyframes animatebottom {
  from {
      height: 0;
      opacity: 0
  }
  to {
      height: 10em;
      opacity: 1
  }
}