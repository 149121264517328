$font-password: "password";
$font-size-14: 0.875rem;

$font-color: #707070;
$color-positive: #59D08E;
$main-backgound: "";

@media only screen and (min-width: 992px) {
    .navbar{
        display: none !important;
    }    
    .common-header-main{
        display: none;
    }
    .common-header-main-desktop{
        animation: fadeIn 500ms ease-in-out;
        z-index: 1;
        font-family: "montserrat";
        padding: 0 2em;
        // position: absolute;
        height: 4.5em;
        z-index: 4;
        display: grid;
        align-items: center;
        margin: 0;
        // padding: 0;
        // border-bottom: 0.01em solid #9e9e9e48;
        .header-tab{
        color: #9E9E9E;
        font-size: $font-size-14;
        align-items: center;
        display: grid;
        label{
          cursor: pointer;
        }
        .mainnet{
            label{
            display: flex;
            img{
                margin-right: 0.5em;
            }
            }
        }
        }
        .input-search{
        font-size: 	0.875rem;
        background: transparent;
        border: none;
        text-align: end;
        color: #9E9E9E;
        padding: 0 1em;
        }
        .input-search:focus{
        outline: none;
        color: #9E9E9E;
        }
        .input-search::placeholder{
        color: #9E9E9E;
        }
        button{
        background-color: transparent;
        border: none;
        display: flex;
        }
        .connect-btn{
          white-space: nowrap;
          max-width:11em;
        border: none;
        font-size: 1rem;
        // position: absolute;
        // top: 1.5em;
        // right: 0em;
        // width: 15em;
        border-radius: 0.5em;
        text-transform: capitalize;
        font-weight: normal;
        padding: 0.3rem 0.2em;
        text-align: center;
        display: flex;
        align-items: center;
        background: #FFDC00;
        font-size: $font-size-14;
        img{
            margin-left: 0.2em;
        }
          label{
            color: #2A2A2A;
          }
        }
        .round-btn{
        background: #484848;
        width: 2.5em;
        height: 2.5em;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 50%;
        // animation: moveToleft 0.8s ease-in;

        }
        .back-btn{
        display: flex;
        justify-content: start;

        &.after{
            .round-btn{
            animation: moveToRight 0.8s ease-in;
            background: #484848;
            width: 100%;
            height: 2.5em;
            justify-content: end;
            padding: 1em;
            display: flex;
            align-items: center;
            border-radius: 5em;
            }
        }
        }
        .share-btn{
        display: flex;
        justify-content: end;
        }
        .connect-btn-login{
        border: none;
        font-size: 1rem;
        // position: absolute;
        // top: 1.5em;
        // right: 0em;
        max-width: 10em;
        border-radius: 0.5em;
        text-transform: capitalize;
        font-weight: normal;
        padding: 0.5rem 1em;
        text-align: center;
        display: flex;
        align-items: center;
        background: #2A2A2A;
        font-size: $font-size-14;
        label{
            color: #FFDC00;
            text-overflow: ellipsis;
            overflow: hidden; 
            // width: 1px; 
            white-space: nowrap;
            margin-right: 0.5em;
        }
        }
        .setting-container{
          z-index: 4;
          top: 3em;
          background: #2A2A2A;
          position: absolute;
          bottom: -1.8em;
          width: 13em;
          border-radius: 0.5em;
          height: 2.5em;
          right: -1em;
          transform: translate(-8%);
          align-items: start;
          display: grid;
          padding: 0.5em;
          &.recover-key{
            height: 12em;
            width: 15em;
            padding: 1em;
            align-items: center;
            z-index: 99999;
            label{
              color: #cdc7c7;
              text-align: start;
              font-size: medium;
              span{
                font-size: small;
              }
            }
            .recover-btn{
              // margin-top: 1em;
              display: flex;
              justify-content: center;
              padding: 0.3rem 1em;
              text-align: center;
              display: flex;
              align-items: center;
              background: #FFDC00;
              font-size: $font-size-14;
              border-radius: 0.5em;
              color: #000000;
            }
            .disconnect-btn{
              margin-top: 1em;
            }
          }
          &.more{
            top: 2em;
            right: 1em;
            height: 27.2em;
            z-index: 4;
            // transform: translate(-5%);
          }
          &.network {
            top: 2.5em;
            right: 0em;
            height: 3em;
            width: 10em;
            padding: 0em 1em;
            align-items: center;
            img{
              margin-right: 0.2em;
            }
            label {
              color: #cdc7c7;
              text-align: start;
              font-size: medium;
              span {
                font-size: small;
              }
            }
          }
          .more-btn{
              padding: 0.5em;
              border: none;
              text-transform: capitalize;
              background-color: transparent;
              box-shadow: none;
              color: #9E9E9E;
              display: flex;
              align-items: center;
              justify-content: start;
              font-size: small;
              cursor: pointer;
              svg{
              margin-right: 0.5em;
              // background: linear-gradient(314.37deg, rgba(255, 255, 255, 0) 8.93%, #9E9E9E 135.23%);
              }
          }
          .more-btn:hover{
            color: #6f6e84;
            svg{
            color: #6f6e84 !important;
            }
          }
          .disconnect-btn{
            // margin-top: 1em;
              border: none;
              text-transform: capitalize;
              background-color: transparent;
              box-shadow: none;
              color: #9E9E9E;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: small;
              svg{
              margin-right: 0.5em;
              }
          }
          .disconnect-btn:hover{
              color: #FA6364;
              svg{
              color: #FA6364 !important;
              }
          }
        }
        .boading-btn{
        border: none;
        font-size: 1rem;
        // position: absolute;
        // top: 1.5em;
        // right: 0em;
        // width: 15em;
        border-radius: 0.5em;
        text-transform: capitalize;
        font-weight: normal;
        padding: 0.3rem 1em;
        text-align: center;
        display: flex;
        align-items: center;
        background: #FFDC00;
        font-size: $font-size-14;
          label{
            color: #2A2A2A;
          }
        }
    }

    .home-container{
        // background: #1A1A1A;
        // background: transparent;
        position: relative;
        z-index: 1;
        // height: 100vh;
        // padding: 0 !important;
        .mobile-version{
            display: none;
        }
        .line{
            background: #393939;
            height: 0.05em;
        }
        .desktop-version{
            display: flex;
            height: 100%;
            font-family: 'cabin';
            .sidebar-container{
              // height: 100%;
              z-index: 1;
              border-right: 0.1em solid #393939 ;
              .trending-image{
                font-family: "montserrat";
                font-weight: bold;
                padding-top: 2em;
                width: 10em;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                font-size: 	0.75rem;
                color: #9E9E9E;
                label{
                  animation: fadeInLeft 500ms ease-in-out;
                  position: absolute;
                  width: 7em;
                  right: 0em;
                }
                img{
                  margin-right: 0.5em;
                }
                .line{
                  z-index: 1;
                  background: #FFDC00;
                  position: absolute;
                  width: 2em;
                  height: 0.1em;
                  padding: 0;
                  top: 5.2em;
                  transform: rotate(-90deg);
                }
              }
              .trending-image:first-child{
                color: #FFDC00;
              }
              .trending-image:last-child{
                .line{
                  display: none;
                }
              }
            }
            .content-section{
              z-index: 1;
              padding: 2em;
              padding-left: 5%;
              padding-right: 5%;
              .banner-container{
                padding: 0;
                padding-bottom: 2em !important;
                img{
                  display: none;
                }
                .banner-text-container{
                  animation: fadeInUp 500ms ease-in-out;
                  margin-top: 1em;
                  text-align: start;
                  width: 19.7em;
                  margin-left: 0;
                  // margin-right: auto;
                  .title {
                    width: auto;
                    padding: 0;
                    font-family: "montserrat";
                    text-transform: uppercase;
                    z-index: 1;
                  }
                  .text {
                    width: auto;
                    z-index: 1;
                    font-family: "cabin";
                    text-transform: lowercase;
                    padding: 0;
                  }
                }

              }
              .banner-desktop{
                animation: fadeIn 500ms ease-in-out;
                padding-bottom: 2em;
                .weekly-profit{
                  color: #9E9E9E;
                  height: 5em;
                  margin-top: auto;
                  margin-bottom: auto;
                  .weekly-profit-title{
                    width: 100%;
                    font-size: 	0.875rem;
                  }
                  .weekly-profit-price{
                    width: 100%;
                    color: #EDEBEB;
                    font-size: 1.5rem;
                    font-weight: bold;
                    align-items: center;
                    display: flex;
                    span{
                      color: #B1B1C9;
                      font-size: 0.75rem;
                      margin-left: 0.5em;
                    }
                  }
                  .weekly-profit-rate{
                    width: 100%;
                    color:#59D08E;
                    span{
                      color: #9E9E9E;
                      margin-left: 0.5em;
                    }
                  }
                }
                .process-bar-container{
                  background: #0d0d0d;
                    &.cast-image{
                      background-image: url(./assets/images/background/progress.jpg);
                      background-size: auto 100%;
                      background-position: center center;
                      background-repeat: no-repeat;
                    }
                    // background: #000000;
                    // width: 100%;
                    overflow: hidden;
                    padding: 1em 0;
                    height: 15.188em;
                    // color: #736747;
                    border: 0.1em solid #736747;
                    padding: 1.5em;
                    // box-shadow: inset 0px 4px 4px rgba(240, 160, 0, 0.25);
                    border-radius: 1em;
                    .report-note-container{
                      span{
                        font-size: 0.875rem;
                        font-weight: bold;
                      }
                      label{
                        font-size: 0.75rem;
                      }
                    }
                    .progress-bar-report{
                      position: relative;
                      transform: rotate(-90deg);
                      // height: 12em;
                      .progress-bar-col{
                        position: absolute;
                        left: -9rem;
                        top: -1rem;
                        display: flex;
                        align-items: center;
                        .progress{
                          width: 13em;
                          background: #736747;
                          border-radius: 0.5em;
                          .progress-bar{
                            background: #F0A000;
                            border-radius: 0.5em;
                          }
                        }
                        label{
                          margin-right: 0.8em;
                          transform: rotate(90deg);
                          color:#FEFEFE;
                        }
                      }
                  }
                }
              }
              .trending-trade-container{
                .header-container-home{
                  display: none;
                }
                .trending-card-container{
                  animation: fadeInUp 500ms ease-in-out;
                  &.mobile-version{
                    display: none;
                  }
                  &.desktop-version{
                    display: flex;
                    padding: 0;
                    .card-row{
                      padding: 0 0.5em;
                      // height: 5em;
                      .trending-card{
                        padding: 1em 0.5em;
                        align-items: flex-start;
                        .trending-value{
                          .label{
                            font-size: 0.75rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .home-market-container{
                animation: fadeInUp 500ms ease-in-out;
                .header-container-home{
                  text-align: start;
                }
                .market-data-container{
                  background: #1A1A1A;
                  // background-color: transparent;
                  border: 1px solid #2A2A2A;
                  border-radius: 12px;
                }
              }
              .announcement-container{
                padding: 2em 0;
                padding-bottom: 0;
                animation: fadeInLeft 500ms ease-in-out;
                .tab-bar-container{
                  display: none;
                }
                .annoucement-card-container{
                  .announcement-content-container{
                    padding: 0;
                  }
                }
              }
              .header-market-container{
                .mobile-version{
                  display: none;
                }
                .desktop-version{
                  display: block;
                  justify-content: end;
                  align-items: center;
                  margin: 1em 0;
                  .market-type-container{
                    font-size: 0.875rem;
                    margin: 0.5em;
                    height: 2.2em;
                    justify-content: end;
                    margin-right: 0;
                    .active{
                      padding: 0.2em;
                      background: #2A2A2A;
                      width: auto;
                      border-radius: 0.5em;
                      margin: 0 0.2rem;
                      label{
                        background: #1A1A1A;
                        color: #EDEBEB;
                        width: 100%;
                        border-radius: 0.5em;
                        padding: 0.2em 1em;
                      }
                    }
                    div{
                      padding: 0.2em;
                      background: #2A2A2A;
                      width: auto;
                      border-radius: 0.5em;
                      margin: 0 0.2rem;
                      label{
                        color: #9E9E9E;
                        width: 100%;
                        border-radius: 0.5em;
                        padding: 0.2em 1em;
                      }
                    }
                  }
                  .market-type-container::-webkit-scrollbar {
                    display: none;
                  }
                  .market-filter-container{
                    font-size: 1rem;
                    background: #2A2A2A;
                    border-radius: 0.8em;
                    // margin: 1em;
                    align-items: center;
                    padding: 0 0.2em;
                    height: 2.2em;
                    .filter-label{
                      // padding: 0.2em;
                      &.active{
                        label{
                          background: #1A1A1A;
                          border-radius: 0.5em;
                          padding: 0.1em 0.5em;
                          color: #FFDC00;
                        }
                      }
                      label{
                        padding: 0.5rem 0.8rem;
                      }
                    }
                  }
                  .controls-container{
                    justify-content: end;
                    padding: 0;
                    margin: 0;
                    .controls{
                      padding: 0;
                      border-radius: 0.5em;
                      .segment{
                        min-width: 6em;
                        // height: 2em;
                      }
                      .segment label {
                        padding: 0.4em;
                      }
                    }
                    .controls::before{
                      border-radius: 0.5em;
                      top: 0.2em;
                      bottom: 0.2em;
                      left: 0.2em;
                      width: 5.5em;
                    }
                  }
                }
              }
              .market-listing-container{
                border-radius: 1em;
                background: #1A1A1A;
                padding: 0;
                .market-listing-box div::-webkit-scrollbar {
                  display: none;
                }
                .market-listing-box{
                  height: 40.5em;
                  animation: fadeInUp 500ms ease-in-out;
                  border: 0.5px solid #393939;
                  border-radius: 1em;
                  // padding: 1em;
                  .listing-header-container{
                    background: #2A2A2A;
                    border-radius: 1em 1em 0 0;
                    color: #EDEBEB;
                    font-size: 	0.75rem;
                    padding: 0.5em;
                    font-family: 'montserrat';
                  }
                  .listing-container{
                    background: #1a1a1a;
                    margin: 1.5em 0;
                    align-items: center;
                    font-family: 'cabin';
                    .title{
                      label{
                        margin-left: 1em;
                        font-size: 1rem;
                        color: #EDEBEB;
                        span{
                          color: #9091A0;
                          margin-left: 0.2em;
                          font-size: 0.875rem;
                        }
                      }
                      img{
                        border-radius: 50%;
                      }
                    }

                    .text{
                      color: #EDEBEB;
                      &.red{
                        color: #FA6364;
                      }
                      &.green{
                        color: #59D08E;
                      }
                    }
                  }
                }
              }
            }
            .background-image{
              padding: 0;
              overflow: hidden;
              position:absolute;
              width: 100%;
              height: auto;
              left: 0;
              // z-index: 0;
              // top: 50%;
              // transform: translate(0,50%);
              img { 
                height: 140vh;
              }
            }
        }
    }
    .profile-container{
        background: transparent;
        height: auto;
        position: relative;
        height: 100vh;
        .line{
            background: #393939;
            height: 0.05em;
        }
        .profile-content{
            display: none;
        }
        .profile-layout-container{
            display: none;
        }
        .profile-desktop-version{
            display: flex;
            height: 100%;
           
            .sidebar-container{
                // height: 100%;
                border-right: 0.1em solid #393939 ;
            }
            .content-section{
                padding: 5%;
                .profile-detail-container{
                  font-family: 'montserrat';
                  color: #EDEBEB;
                  padding-bottom: 2em;
                  .profile-detail-picture{
                    img{
                      margin-right: 1em;
                    }
                    label{
                      white-space: nowrap;
                      overflow: hidden;
                      vertical-align:middle;
                    }
                    .text-ellipsis {
                      display: inline-block;
                      width: calc(12% + 1.2em);
                      text-overflow: ellipsis;
                    }
                    .text-indent {
                      display:inline-flex;
                      // width: calc(14% - 1.2em);
                      justify-content:flex-end;
                    }
                  }
                  .profile-detail-wallet{
                    .profile-wallet-box{
                      text-align: end;
                      border-right: 1px solid rgba(240, 160, 0, 0.3);
                      label{
                        width: 100%;
                        &.title{
                          font-family: 'cabin';
                          color: #9E9E9E;
                          font-size: x-small;
                        }
                        &.value{
                          font-family: 'montserrat';
                          color: #FEFEFE;
                          font-size: 	1.125rem;
                        }
                        &.profile-detail-wallet{
                          font-family: 'montserrat';
                          color: #B1B1C9;
                          font-size: 0.75rem;
                        }
                      }
                    }
                  }
                }
                .campaign-container{
                    animation: fadeIn 500ms ease-in-out;
                    color: #EDEBEB;
                    background: #000001;
                    font-weight: bold;
                    font-size: 1rem;
                    border-radius: 1em;
                    padding: 1em;
                    .mobile-version{
                        display: none;
                    }
                    .desktop-version{
                        display: unset;
                        .unlock{
                            font-size: 1.625rem;
                            background: linear-gradient(275.61deg, #FFDC00 4.67%, rgba(237, 235, 235, 0) 123.76%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                        }
                        .campaign{
                            color: #F0A000;
                            font-size: 	1.25rem;
                          }
                          .alphapass{
                            color: #FFDC00;
                            font-size: 1.25rem;
                          }
                          .description-container{
                            color: #9E9E9E;
                            label{
                              line-height: 1.5em;
                              font-size: $font-size-14;
                              font-weight: normal;
                            }
                          }
                          .gold-line{
                            background-color: rgba(240, 160, 0, 0.3);
                            margin-bottom: 01em;
                            height: 0.1em;
                          }
                          .check-in-button{
                            width: 10em;
                            margin-top: 0em;
                            margin-bottom: 1.5em;
                            margin-left: auto;
                            margin-right: auto;
                            button{
                              font-weight: bold;
                              text-transform: capitalize;
                              border-radius: 2em;
                              background: linear-gradient(256.56deg, #F0A000 38.01%, #FFDC00 102.8%);
                              box-shadow: 0px 0px 4px #FFDC00;
                              font-size: 1.125rem;
                              span{
                                color: #1A1A1A;
                              }
                            }
                          }
                          .checkin-container{
                            margin-top: 1em;
                            margin-bottom: 3em;
                            margin-left: 0;
                            margin-right: 0;
                            .checkin-line{
                              background: transparent;
                              border-radius: 0 1em 1em 0;
                              height: 4em;
                              border: 0.1em solid #FFDC00;
                              border-left: none;
                              position: relative;
                            }
                          }
                          .nft-container{
                            align-items: center;
                            justify-content: center;
                            img{
                              width: 28em;
                              height: 17em;
                            }
                          }
                    }
                }
                .referrals-container{
                    padding-top: 1em;
                    .header-container{
                      animation: fadeInLeft 500ms ease-in-out;
                      text-align: start;
                      font-family: 'montserrat';
                      span{
                        font-family: 'cabin';
                      }
                    }
                    .referrals-box{
                      animation: fadeInUp 500ms ease-in-out;

                      &.mobile-version{
                        display: none;
                      }
                      &.desktop-version{
                        display: flex;
                        padding: 0;
                        background-color: transparent;
                        .box{
                          // margin-top: 1em;
                          border-radius: 1em;
                          background: #242628;
                          padding: 1.5em 1em;
                          .referral-row{
                            .line{
                              opacity: 0.1;
                              border: 1px solid #9E9E9E;
                              transform: matrix(1, 0, 0, 1, 0, 0);
                            }
                            .title{
                              font-family: 'montserrat';
                              color: #9E9E9E;
                              .referral-link{
                                background: linear-gradient(270deg, rgba(237, 235, 235, 0) 6.9%, #EDEBEB 61.28%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                text-fill-color: transparent;
                              }
                            }
                            .value{
                              color: #EDEBEB;
                              font-weight: bold;
                              font-family: 'montserrat';
                            }
                            .button-share{
                              button{
                                &.active{
                                  background: #1A1A1A;
                                  border: 0.1em solid #484848;
                                }
                                border: none;
                              }
                              span{
                                color: #9E9E9E;
                              }
                            }
                            &.noted{
                              padding: 0;
                              padding-bottom: 1em;
                              font-size: x-small;
                              font-family: 'cabin';
                            }
                          }
                          &.padding{
                            padding: 1em;
                          }
                        }
                      }
                    }
                }
                .metrics-container{
                  padding-top: 2em;
                  .header-container{
                    animation: fadeInLeft 500ms ease-in-out;
                    font-family: 'montserrat';
                    span{
                      font-family: 'cabin';
                    }
                  }
                  .metrics-box-container{
                    animation: fadeInUp 500ms ease-in-out;

                    padding: 1em 0;
                    color: #EDEBEB;
                    font-family: 'cabin';
                    &.box{
                      padding: 1em;
                      background: #242628;
                      border-radius: 1em;
                      border: 1px solid #2A2A2A;
                      box-shadow: inset -2px -2px 17px rgba(101, 96, 156, 0.25);
                    }
                    .title{
                     font-size: 0.875rem;
                     font-family: 'cabin';
                    }
                    .price{
                      font-family: 'Montserrat';
                      font-size: 1.5rem;
                      color: #9E9E9E;
                      &.green{
                        color: #59D08E;
                      }
                      &.red{
                        color: #FA6364;
                      }
                    }
                    .date{
                      font-family: 'cabin';
                      color: #6F7283;
                      font-size: 0.75rem;
                    }
                  }
                }
                .leaderboard-container{
                  .header-container{
                    animation: fadeInLeft 500ms ease-in-out;
                    text-align: end;
                    label{
                      color: #EDEBEB;
                      font-size: 1.5rem;
                      font-weight: bold;
                      display: flex;
                      align-items: center;
                    }
                    span{
                      color: #9E9E9E;
                      font-size: 0.875rem;
                    }
                    .badge{
                      background: linear-gradient(256.56deg, #F0A000 38.01%, #FFDC00 102.8%);
                      padding: 0.15em;
                      border-radius: 0.5em;
                      margin-left: 1em;
                      .background{
                        border-radius: 0.35em;
                        background: #1A1A1A;
                        label{
                          display:block;
                          font-size: 0.875rem;
                          padding: 0.5em 1em;
                          background: linear-gradient(256.56deg, #F0A000 38.01%, #FFDC00 102.8%);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                          background-clip: text;
                          text-fill-color: transparent;
                        }
                      }
                    }
                  }
                  .leaderboard-box{
                    margin-top: 1em;
                    border: 0.1em solid #242629;
                    .title{
                      font-size: 0.875rem;
                    }
                    .header-leaderboard{
                      border-radius: 1em 1em 0 0;
                      background: linear-gradient(256.56deg, rgba(240, 160, 0, 0.1) 38.01%, rgba(255, 220, 0, 0.1) 102.8%);
                      .filter-row{
                        font-size: 0.875rem;
                        padding-bottom: 0;
                        padding-top: 1.5em;
                      }
                    }
                    .leaderboard-row{
                      font-family: 'montserrat';
                      padding-left: 0;
                      padding-right: 0;
                      .name{
                        label{
                          font-size: 0.875rem;
                          text-align: center;
                          background: linear-gradient(270deg, rgba(0, 0, 0, 0.59) 6.9%, #edebeb00 61.28%);
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                          background-clip: text;
                          text-fill-color: transparent;
                          font-weight: bold;     
                        }
                      }
                      .revenue{
                        font-size: 0.75rem;
                        .red{
                          color: #FA6364;
                        }
                        .green{
                          color: #59D08E;
                        }
                      }
                    }
                    div:nth-child(odd){
                      .leaderboard-row{
                        margin: 0;
                        background: #242629;
                      }
                    }
                    div:nth-child(even){
                      .leaderboard-row{
                        margin: 0;
                        background: #161719;
                      }
                    }
                    div:last-child{
                      .leaderboard-row{
                        border-radius: 0 0 1em 1em;
                      }
                    }
                  }
                }
                .radio-button-container{
                  .radio-button{
                   width: auto; 
                   .form-check-input:disabled{
                      background-color: #efe0b4;
                      opacity: 1;
                   }
                   .form-check-input[type=radio]:disabled:after {
                    border-radius: 50%;
                    width: 0.625rem;
                    height: 0.625rem;
                    border-color: #000000;
                    background-color: #B4B2AF;
                    transition: border-color;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                   }
                   .form-check-input[type=radio]:before{
                      background: #9E9E9E;
                   }
                   .form-check-input:checked {
                    background-color: #F0A000;
                    border-color: #000000;
                    box-shadow: 0px 0px 5px rgba(255, 220, 0, 0.8);
                   }
                   .form-check-input[type=radio]:checked:after {
                    border-radius: 50%;
                    width: 0.625rem;
                    height: 0.625rem;
                    border-color: #000000;
                    background-color: #FFDC00;
                    transition: border-color;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                   }
                   .date-label{
                    label{
                      color: #9E9E9E;
                      font-size: 0.5625em;
                    }
                   }
                  }
                  .radio-button{
                    padding: 0;
                  }
                  .radio-button:nth-child(1){
                    position: absolute;
                    top: 0.7em;
                    left: 0%;
                  }
                  .radio-button:nth-child(2){
                    position: absolute;
                    top: 0.7em;
                    left: 8%;
                  }
                  .radio-button:nth-child(3){
                    position: absolute;
                    top: 0.7em;
                    left: 16%;
                  }
                  .radio-button:nth-child(4){
                    position: absolute;
                    top: 0.7em;
                    left: 24%;
                  }
                  .radio-button:nth-child(5){
                    position: absolute;
                    top: 0.7em;
                    left: 32%;
                  }
                  .radio-button:nth-child(6){
                    position: absolute;
                    top: 0.7em;
                    left: 40%;
                  }
                  .radio-button:nth-child(7){
                    position: absolute;
                    top: 0.7em;
                    left: 48%;
                  }
                  .radio-button:nth-child(8){
                    position: absolute;
                    top: 0.7em;
                    left: 56%;
                  }
                  .radio-button:nth-child(9){
                    position: absolute;
                    top: 0.7em;
                    left: 64%;
                  }
                  .radio-button:nth-child(10){
                    position: absolute;
                    top: 0.7em;
                    left: 72%;
                  }
                }
                .checkin-desktop-container{
                    margin-top: 1em;
                    margin-bottom: 3em;
                    margin-left: 0;
                    margin-right: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    // justify-content: center;
                    .checkin-line{
                      background: #FFDC00;
                      width: 98%;
                      height: 0.1em;
                      border-left: none;
                    }
                    .check-in-button{
                      font-family: 'montserrat';
                      // width: 10em;
                      // margin-top: 0em;
                      // margin-bottom: 1.5em;
                      // margin-left: auto;
                      // margin-right: auto;
                      button{
                        font-weight: bold;
                        padding: 0.5em 1em;
                        // font-weight: normal;
                        text-transform: capitalize;
                        border-radius: 2em;
                        background: linear-gradient(256.56deg, #F0A000 38.01%, #FFDC00 102.8%);
                        box-shadow: 0px 0px 4px #FFDC00;
                        font-size: 1.125rem;
                        span{
                          color: #1A1A1A;
                        }
                      }
                    }
                }
            }
        }
    }
    .market-container{
        height: 100vh;
        .line{
            background: #393939;
            height: 0.05em;
        }
        .mobile-version{
          // .picture{
              display: none;
          // }
        }
        .desktop-version{
          display: flex;
          height: 100%;
          font-family: 'cabin';
          .sidebar-container{
              // height: 100%;
              border-right: 0.1em solid #393939;
              .header-welcome{
                color: #EDEBEB;
                margin: 1em 0 0 0;
                label{
                  font-size: 0.75rem;
                  font-family: 'montserrat';
                  font-weight: bold;
                  svg{
                    margin-right: 0.5em;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                    // background: linear-gradient(270deg, rgba(237, 235, 235, 0) 6.9%, #EDEBEB 61.28%);
                  }

                }
              }
              .common-header-main{
                padding: 0;
                z-index: 1;
                font-family: "montserrat";
                position: relative;
                height: 5em;
                z-index: 4;
                display: grid;
                align-items: center;
                .input-search{
                  font-size: 	0.875rem;
                  background: transparent;
                  border: none;
                  text-align: end;
                  color: #9E9E9E;
                  padding: 0 1em;
                }
                .input-search:focus{
                  outline: none;
                  color: #9E9E9E;
                }
                .input-search::placeholder{
                  color: #9E9E9E;
                }
              }
              .listing-container{
                animation: fadeInUp 500ms ease-in-out;
                margin: 1.5em 0;
                align-items: center;
                font-family: 'cabin';
                .title{
                  label{
                    margin-left: 0.5em;
                    font-size: 	0.875rem;
                    color: #EDEBEB;
                    span{
                      color: #9091A0;
                      margin-left: 0.2em;
                      font-size: 0.75rem;
                    }
                  }
                  img{
                    border-radius: 50%;
                  }
                }
                .text{
                  text-align: end;
                  font-size: 0.75rem;
                  color: #EDEBEB;
                  .text-rate{
                    width: 100%;
                    font-size: x-small;
                    &.red{
                      color: #FA6364;
                    }
                    &.green{
                      color: #59D08E;
                    }
                  }
                }
              }
              .controls-container{
                margin: 0;
                .segment label{
                  font-size: 1em;
                  font-family: 'cabin';
                }
                .controls{
                  padding: 0em 0.5em;
                }
                .controls::before{
                  top: 0.3em;
                  bottom: 0.3em;
                  left: -0.1em;
                  z-index: 0;
                  margin: 0.1em;
                }
              }
          }          
          .content-section{
            z-index: 1;
            padding: 2em;
            padding-left: 5%;
            padding-right: 5%;
            &.trade{
              padding-left: 1.5em;
              padding-right: 1.5em;
            }
            .header-market-container{
              .mobile-version{
                display: none;
              }
              .desktop-version{
                display: block;
                justify-content: end;
                align-items: center;
                margin: 1em 0;
                .market-type-container{
                  font-size: 0.875rem;
                  margin: 0.5em;
                  height: 2.2em;
                  justify-content: end;
                  margin-right: 0;
                  .active{
                    padding: 0.2em;
                    background: #2A2A2A;
                    width: auto;
                    border-radius: 0.5em;
                    margin: 0 0.2rem;
                    label{
                      background: #1A1A1A;
                      color: #EDEBEB;
                      width: 100%;
                      border-radius: 0.5em;
                      padding: 0.1em 1em;
                    }
                  }
                  div{
                    padding: 0.2em;
                    background: #2A2A2A;
                    width: auto;
                    border-radius: 0.5em;
                    margin: 0 0.2rem;
                    label{
                      color: #9E9E9E;
                      width: 100%;
                      border-radius: 0.5em;
                      padding: 0.1em 1em;
                    }
                  }
                }
                .market-type-container::-webkit-scrollbar {
                  display: none;
                }
                .market-filter-container{
                  font-size: 1rem;
                  background: #2A2A2A;
                  border-radius: 0.8em;
                  // margin: 1em;
                  align-items: center;
                  padding: 0 0.2em;
                  height: 2.2em;
                  .filter-label{
                    // padding: 0.2em;
                    &.active{
                      label{
                        background: #1A1A1A;
                        border-radius: 0.5em;
                        padding: 0.1em 0.5em;
                        color: #FFDC00;
                      }
                    }
                    label{
                      padding: 0.5rem 0.8rem;
                    }
                  }
                }
                .controls-container{
                  justify-content: end;
                  padding: 0;
                  margin: 0;
                  .controls{
                    padding: 0;
                    border-radius: 0.5em;
                    .segment{
                      min-width: 6em;
                      // height: 2em;
                    }
                    .segment label {
                      padding: 0.4em;
                    }
                  }
                  .controls::before{
                    border-radius: 0.5em;
                    top: 0.2em;
                    bottom: 0.2em;
                    left: 0.2em;
                    width: 5.5em;
                  }
                }
              }
            }
            .home-market-container{
              animation: fadeInUp 500ms ease-in-out;
              .header-container-home{
                  text-align: start !important;
                  color: #edebeb;
                  text-align: end;
                  margin-bottom: 1em;
                  label {
                    font-family: "montserrat";
                    font-size: 1.5rem;
                    font-weight: 500;
                    z-index: 1;
                  }
                  span {
                    font-family: "cabin";
                    font-size: 0.875rem;
                    z-index: 1;
                    color: #9e9e9e;
                  }
              }
              .market-data-container{
                  animation: fadeInUp 500ms ease-in-out;
                  background-color: #2a2a2a;
                  border-radius: 1.8em;
                  padding: 1em 0.5em;
                  .market-title {
                    font-family: "cabin";
                    font-size: 0.875rem;
                    color: #9e9e9e;
                  }
                  .market-value {
                    font-family: "montserrat";
                    font-size: 1.25rem;
                    color: #edebeb;
                    font-weight: 600;
                  }
              }
            }
            .content-section{
              padding: 0;
              .coming-soon-label{
                font-family: 'montserrat';
                position: absolute;
                z-index: 1;
                left: 50%;
                top: 50%;
                color: #0a0a0a; 
                background: linear-gradient(256.56deg, #F0A000 38.01%, #FFDC00 102.8%);
                border-radius: 1em;
                border: none;
                padding: 0.5em 1em;
                transform: translate(50%, 50%);
              }
            }
          }
        }
        .desktop-version{
          display: flex;
          height: 100%;
          .market-listing-container{
            border-radius: 1em;
            background: #1A1A1A;
            padding: 0;
            .market-listing-box div::-webkit-scrollbar {
              display: none;
            }
            .market-listing-box{
              animation: fadeInUp 500ms ease-in-out;
              border: 0.5px solid #393939;
              border-radius: 1em;
              overflow: hidden;
              .listing-header-container{
                background: #2A2A2A;
                border-radius: 1em 1em 0 0;
                color: #EDEBEB;
                font-size: 	0.75rem;
                padding: 0.5em;
                font-family: 'montserrat';
              }
              .listing-container{
                z-index: 2;
                background: #1a1a1a;
                position: relative;
                margin: 1.5em 0;
                align-items: center;
                font-family: 'cabin';
                .title{
                  label{
                    margin-left: 1em;
                    font-size: 1rem;
                    color: #EDEBEB;
                    span{
                      color: #9091A0;
                      margin-left: 0.2em;
                      font-size: 0.875rem;
                    }
                  }
                  img{
                    border-radius: 50%;
                  }
                }

                .text{
                  color: #EDEBEB;
                  &.red{
                    color: #FA6364;
                  }
                  &.green{
                    color: #59D08E;
                  }
                }
              }
            }
          }
        }
    }
    .announcement-detail-modal{
      .modal-dialog{
        .modal-content{
          overflow: hidden;
          border-radius: 1rem;
          background: #1A1A1A;
          .modal-body{
            height: 100vh;
            border-radius: 1em;
            // overflow: hidden !important;
            background: #1A1A1A;
            .content-container{
              // height: 100%;
              // border-radius: 5em 5em 0 0;
            }
          }
        }
      }
      .common-header-main{
        display: unset;
        position: absolute;
        align-items: end;
        padding: 1em;
      }
      .content-container{
        min-height:10em;
        // height: 100vh;
        // padding: 1em;
        background: #1A1A1A;
        border-radius: 2em 2em 0 0;
        position: relative;
        margin-top: 16.5em;
        .top-position{
          position: absolute;
          top: -10%;
          left: 10%;
          padding-top: 4em;
          padding-left: 2em;
          padding-right: 2em;
          transform: translate(-10%);
          width: 100%;
          .paragraph{
            margin-top: 3em;
            color: #9E9E9E;
            font-size: 	0.875rem;
          }
        }
        .card-container{
          color: #EDEBEB;
          background: #2A2A2A;
          padding: 1em;
          border-radius: 1em;
          font-family: 'cabin';
          .text{
            // padding-bottom: 1em;
            line-height: 1.5em;
            font-size: 1.125rem;
          }
          .content-author{
            margin: 0.5em 0;
            margin-bottom: 0;
            img{
              margin-right: 0.5em;
            }
            .days{
              color:#9091A0;
              font-size: 0.75rem;
            }
          }
          .line{
            margin: 1em 0;
            border-bottom: 1px solid rgba(117, 118, 131, 0.6);
          }
        }
      }
      .modal-header-image{
        // background-image: url(./assets/images/background/announcement1.png);
        height: 20em;
        width: 100%;
        top: 0;
        right: 0;
        // transform: translate(-10%);
        position: absolute;
        background-size: contain;
      }
    }

}

// /*!****************
//     Font Family
// ******************/
// @font-face {
//     font-family: "montserrat";
//     src: url(./assets/fonts/Montserrat-Regular.otf);
//   }
  
//   @font-face {
//     font-family: "cabin";
//     src: url(./assets/fonts/Cabin-Regular.ttf);
//   }
  
  
//   @font-face {
//     font-family: "password";
//     font-style: normal;
//     font-weight: 400;
//     src: url(./assets/fonts/password.ttf);
//   }
  
//   /*!****************
//     Font Family END
//   ******************/
  
//   @keyframes blinker {
//     50% {
//       opacity: 0;
//     }
//   }
  
//   @keyframes moveToRight {
//     0% {
//       width: 20%;
//     }
//     100% {
//       width: 100%;
//     }
//   }
  
//   @keyframes moveToLeft {
//     0% {
//       width: 100%;
//     }
//     100% {
//       width: 20%;
//     }
//   }

//   @keyframes fadeIn {
//     0% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
//   }

//   @keyframes fadeInUp {
//     0% {
//         opacity: 0;
//         -webkit-transform: translateY(40px);
//     }
//     100% {
//         opacity: 1;
//         -webkit-transform: translateY(0);
//     }
//   }

//   @keyframes fadeInLeft {
//     0% {
//         opacity: 0;
//         transform: translateX(80px);
//     }
//     100% {
//         opacity: 1;
//         transform: translateY(0);
//     }
//   }

// @keyframes fadeInRight {
//   0% {
//       opacity: 0;
//       transform: translateX(-80px);
//   }
//   100% {
//       opacity: 1;
//       transform: translateY(0);
//   }
// }

// @keyframes swipeToLeft {
//   0% {
//       -webkit-transform: translateX(5em);
//   }
//   100% {
//       -webkit-transform: translateX(0);
//   }
// }